.__stepp {
  display: flex;
  gap: 6px;
  width: calc(100vw - 24px);
  position: relative;
  filter: drop-shadow(0px 0px 4px #1115);
  top: 2px;
  left: 4px;
}

.stepper_container {
  align-items: center;
  min-height: 50px;
  height: 50px;
  justify-content: center;
  gap: 8px;

  display: flex;
  flex: 1 1;
  max-width: 100%;
  max-width: 100%;
}

.__stepp .step_circle {
  align-items: center;
  min-height: 50px;
  height: 50px;
  justify-content: center;
  border-radius: 8px;
  /* box-shadow: 0px 0px 12px #1114; */
  overflow: hidden;
  display: flex;
  min-width: 50px;
  width: fit-content;
  font-size: 22px;
  font-family: var(--font1);
  padding: 16px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  gap: 6px;
  font-weight: 600;
  background-color: white;
  border: 1px solid var(--blue);
  color: var(--blue);
  transition: all 0.2s var(--bc);
}

.step_circle.active {
  align-items: center;
  background-color: var(--blue) !important;
  color: white;
  flex: 1;
  font-weight: 600;
}
.stepper_container .step_title {
  font-size: 13px;
  flex-grow: 1;
  font-weight: 500;
  cursor: pointer;
  font-family: var(--font1);
  position: relative;
  min-width: fit-content;
  transition: all 0.2s var(--bc);
  opacity: 0;
  width: 0;
  white-space: nowrap;
  display: none;
  color: black;
}
.stepper_container .step_title.visible {
  width: fit-content;
  opacity: 1;
  display: inline;
  color: white;
}
.stepper_container .step_icon_cont {
  display: flex;
  color: #999;
  margin-left: 50px;
}

.__stepp .back {
  height: 46px;
  width: 46px;
  max-width: 46px;
}

@media (min-width: 720px) {
  .stepper_container .step_title {
    opacity: 1;
    display: inline;
    width: fit-content;
    flex: 1;
    font-size: 15px;
  }

  .stepper_container {
    height: 56px;
    min-height: 56px;
    gap: 12px;
  }
  .__stepp .step_circle {
    flex: 1;
    padding: 20px;
    height: 56px;
    width: 56px;
    min-width: 56px;
    margin-top: 4px;
    font-size: 28px;
    gap: 8px;
  }
  .step_title {
    font-size: 16px;
  }

  .__stepp {
    display: flex;
    gap: 8px;
    width: calc(100vw - 32px);
    margin: 0 auto 16px;
    /* filter: drop-shadow(0px 0px 12px #1114); */
  }
}
