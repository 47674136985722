._user_info .in {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}
/* ._user_info .info h3 {
  color: var(--blue);
} */
._user_info .r-- {
  flex-direction: column;
  max-width: unset;
  justify-content: space-between;
}
._user_info .r-- > div {
  flex: 1;
  min-width: 300px;
}
._user_info.PF {
  position: static !important;
  height: calc(100vh - 0px);
  overflow-y: scroll;
  justify-content: start;
  background: white;
  /* background: linear-gradient(30deg, var(--blue) 50%, #fff 50.1%); */
}
._user_info h2 {
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin: 12px 0;
  gap: 4px;
}

._user_info h2 svg {
  color: var(--blue);
}
._user_info .loading_info {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 840px) {
  ._user_info.PF {
    flex-direction: row;
    align-items: start;
    /* padding: 0 40px; */
  }

  ._user_info.PF .header {
    position: absolute;
    top: 0;
  }
  ._user_info .r-- {
    max-width: 360px;
    align-self: start;
    margin-top: 60px;
    position: sticky;
    top: 60px;
    flex: 1;
  }
  ._user_info .user_ads {
    background: transparent;
    margin-top: 10px;
    z-index: 10;
  }
}
