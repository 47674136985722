._ads {
  transition: all 0.3s var(--bc);
}

._ads .header {
  display: flex;
  align-items: center;
  padding: 12px 16px 16px;
  justify-content: space-between;
  gap: 20px;
  margin-top: 56px;
  flex-direction: column;
  z-index: 3;
  position: relative;
  background: white;
}

._ads .header .search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  border-radius: 26px;
  height: 40px;
  padding: 16px;
  box-sizing: border-box;
  color: var(--blue);
  background: #2196f322;

  box-shadow: 0px 0px 2px #1113;
  box-shadow: inset 0px 0px 2px #1113;
  /* color: var(--blue); */
}

._ads .header .search input {
  border: none;
  flex: 1;
  outline: none;
  background-color: transparent;
  font-family: var(--font1);

  font-weight: 500;
}
._ads .controls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  box-sizing: border-box;
  position: relative;
  top: -2px;
}
._ads .tabs {
  display: flex;
  align-items: center;
  justify-content: end;
  background: white;
  box-shadow: 0px 0px 4px #1114;
  /* overflow: hidden; */
  flex: 1 1;
  position: relative;
  /* padding: 0 16px; */
}
._ads .tabs .tab {
  height: 30px;
  display: flex;
  align-items: end;
  justify-content: center;
  padding: 6px;
  box-sizing: border-box;
  width: 150px;
  /* box-sizing: border-box; */
  flex: 1 1;
  font-weight: 600;
  font-size: 14px;
  gap: 3px;
  color: #777;
  /* font-family: var(--font1); */
  /* letter-spacing: -0.2px; */
  transition: all 0.3s var(--bc);
  overflow: hidden;
  position: relative;
}
._ads .tabs .tab.active {
  color: var(--blue);
}
._ads .tabs .tab svg {
  height: 18px;
  width: 18px;
  align-self: end;
  display: flex;
  align-items: center;
  justify-content: center;
}

._ads .lists_container {
  position: relative;
}
._ads .lists_container > .wishlist,
._ads .lists_container > .ads {
  min-width: 100%;
  padding: 16px 0;
  transition: all 0.2s var(--bc);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 180px);
  overflow-y: scroll;
}
._ads .active_bar {
  border: 1px solid var(--blue);
  width: 50%;
  position: absolute;
  border-radius: 2px;
  bottom: -2px;
  /* bottom: 0; */
  left: 0;
  transition: all 0.2s var(--bc);
}
