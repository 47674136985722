.view_listing .images_container {
  height: 60vw;
  width: 100%;
  position: relative;
  min-height: 200px;
  overflow: hidden;
  max-height: 460px;
  border-radius: 8px;
  overflow: hidden;
}

.view_listing ._left,
._right {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 300px;
}
.view_listing ::-webkit-scrollbar {
  display: none;
}
.view_listing {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  padding-bottom: 10vh;
}
.view_listing * {
  margin: 0;
}

.view_listing .images {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: scroll;
  display: flex;
  top: 0;
  left: 0;
  pointer-events: none;
  border-radius: 4px;
  transition: all 0.3s var(--bc);
  /* display: none; */
  /* overflow: hidden; */
}

.view_listing .tile .info > svg {
  margin-left: auto;
  height: 32px;
  width: 32px;
  position: relative;
  left: 8px;
  padding: 4px;
  border-radius: 50%;
  fill: var(--blue);
  transition: all 0.3s var(--bc);
  cursor: pointer;
}

.view_listing .tile .info:hover > svg {
  background-color: #1111;
}

.left_img_cont {
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0px 0px 8px #1113;
}

.view_listing .slides,
._gallery .slides {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 10;
  gap: 2px;
  opacity: 100%;
  transition: all 0.3s var(--bc);
  width: 100%;
  justify-content: center;
  align-items: end;
  height: 15%;
  background: linear-gradient(180deg, transparent, #1113);
  padding: 5px;
  box-sizing: border-box;
}

.view_listing .slides .dot,
._gallery .dot {
  height: 5px;
  width: 5px;
  background: transparent;
  border-radius: 50%;

  border: 1px solid white;
  filter: drop-shadow(0px 0px 8px #1113);
}

.view_listing .slides .dot.active,
._gallery .dot.active {
  opacity: 100%;
  transform: scale(1.2);
  background-color: white;
}

.view_listing .wishlist {
  height: 36px;
  width: 36px;
  position: absolute;
  top: 6px;
  right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  overflow: hidden;
  border: none;
  z-index: 4;
  border-radius: 50%;
  filter: drop-shadow(2px 2px 8px #1115);
}
.view_listing .wishlist svg {
  fill: transparent;
  stroke: var(--red);
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  stroke-width: 2;
  justify-content: center;
}
.view_listing .wishlist.active svg {
  fill: var(--red);
}

.view_listing .images .img_cont {
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  min-width: 100%;
  pointer-events: none;
  border-radius: 6px;
  position: absolute;
  transition: all 0.3s var(--bc);
  max-width: 100%;
}
.view_listing .images .img_cont img {
  height: 100%;
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  left: 0;
  min-width: 100%;
}
._gallery .images .img_cont {
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
  min-width: 100%;
  pointer-events: none;
  border-radius: 0px;
  transition: all 0.3s var(--bc);
  max-width: 100%;
  position: absolute;
  top: 0%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

._gallery *::-webkit-scrollbar,
._gallery ::-webkit-scrollbar {
  display: none;
}
._gallery .images img {
  transform-origin: top left;
  /* position: absolute; */
  height: -moz-fit-content;
  height: fit-content;
  left: 0px;
  min-height: fit-content;
  max-width: fit-content;
  transition: all 0.2s var(--bc);
  width: 100%;
  object-fit: contain;
  align-self: center;
  min-width: 100%;
}

.view_listing .tile {
  padding: 16px;
  box-sizing: border-box;
  background: white;
  border-radius: 6px;
  filter: drop-shadow(0px 0px 4px #1115);
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.view_listing .title_and_price h2 {
  font-weight: 600;
  word-wrap: unset;
  line-height: 1.2;
  font-size: 19px;
  font-family: var(--font1);
  color: black;
  text-transform: capitalize;
}

.view_listing .title_and_price h3 {
  font-weight: 500;
  color: #777;
}

.view_listing h1.price {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: baseline;
  height: 36px;
  box-sizing: border-box;
}
.view_listing h1.price p {
  position: relative;
  top: 5px;
}

.view_listing h1.price span {
  color: var(--blue);
  font-weight: 600;
  font-size: 28px;
  font-family: var(--font1);
  position: relative;
  margin: 0;
  top: 5px;
}

.view_listing h1.price img {
  height: 28px;
  border-radius: 4px;
  align-self: flex-end;
}

.view_listing h1 {
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: var(--font2);
}

.view_listing h1 svg {
  height: 24px;
  width: 24px;
  fill: var(--blue);
  stroke: white;
  stroke-width: 0.2;
}

.view_listing .field {
  display: flex;
  width: 100%;
  gap: 20px;
  font-size: 14px;
  height: 30px;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
}

.view_listing .extra_fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.view_listing .extra_fields p.key {
  color: #777;
  font-weight: 500;
  font-family: var(--font1);
  font-size: 15px;
}

.view_listing .extra_fields p.val {
  font-weight: 500;
  /* width: 50%; */
  color: black;
  text-align: right;
  /* font-family: var(--font2); */
}

.view_listing .description p {
  font-family: var(--font2);
  line-height: 1.4;
  font-size: 17px;
  color: black;
}

.view_listing .price_hidden {
  font-size: large;
  font-weight: 600;
  color: var(--blue);
}
.view_listing .map {
  height: 25vh;
  min-height: 200px;
  max-height: 300px;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 0px 4px #1112;
}

.view_listing .main {
  position: relative;
  display: flex;
  width: 100%;
  margin: auto;
  gap: 12px;
  flex-direction: column;
}

.view_listing .location {
  gap: 10px;
}

.view_listing .seller_info .info {
  display: flex;
  align-items: center;
  gap: 6px;
}

.view_listing .seller_info img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px #1112;
  object-fit: cover;
  min-width: 60px;
  min-height: 60px;
}

.view_listing .seller_info .info > div {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
}

.view_listing .seller_info .info > div p.name {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.view_listing .seller_info .info > div .member_since {
  font-size: 13px;
  display: flex;

  align-items: center;
  gap: 4px;
  color: #333;
}

.view_listing .seller_info .info > div .member_since span {
  font-weight: 600;
}

.view_listing .location .location_name {
  margin-bottom: -5px;

  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.view_listing .send_message {
  height: 42px;
  display: flex;
  gap: 6px;
  align-items: center;
}

.view_listing .send_message input {
  border: 1px solid var(--blue);
  box-shadow: 0px 0px 4px #ccc;
  height: 100%;
  flex: 1;
  border-radius: 23px;
  font-size: 15px;
  box-sizing: border-box;
  padding: 0px 16px;
  outline: none;
}

.view_listing .send_message button {
  background: none;
  border: none;
  display: flex;
  background-color: var(--blue);
  height: 42px;
  width: 42px;
  border-radius: 50%;
  color: white;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 8px #ccc;
  cursor: pointer;
  transition: all 0.3s var(--bc);
}

.view_listing .send_message button:hover {
  background-color: var(--blue-dark);
}

.view_listing .id_and_report {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view_listing .images_container .close {
  display: none;
}

.view_listing .posted_on .actions {
  display: flex;
  gap: 5px;
  height: 24px;
  justify-content: center;
  align-items: center;
}

.view_listing .posted_on .actions button {
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s var(--bc);
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue);
  color: white;
  position: absolute;
  border-radius: 6px;
  /* margin: -4px; */
  box-shadow: 0px 0px 8px #1113;
  top: 10px;
  right: 10px;
}

.view_listing .posted_on .actions button svg {
  height: 18px;
  width: 18px;
  fill: white;
}

.view_listing .share svg {
  fill: var(--blue);
}

.view_listing .share svg:hover {
  fill: var(--blue-dark);
}

.view_listing .report svg {
  fill: var(--red);
}

.view_listing .report svg:hover {
  fill: var(--red-dark);
}

.view_listing .posted_on .actions button:hover {
  transform: scale(1.1);
}

.view_listing .report svg {
  height: 20px;
  width: 20px;
}

.view_listing .tags > div {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  filter: drop-shadow(0px 0px 4px #1114);
}
.view_listing .price .country {
  margin-left: auto;
  font-size: 24px;
  font-weight: 500;
}

.view_listing .tag {
  border-radius: 4px;
  padding: 8px 16px;
  background-color: var(--blue);
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: white;
  box-sizing: border-box;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.view_listing .view_images.images_container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: transparent;
}

.view_listing .view_images.images_container .carousel_button svg {
  height: 50px;
  width: 50px;
}

.view_listing .view_images.images_container .images {
  height: 100%;
  position: absolute;
  width: 100%;
  overflow: scroll;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  pointer-events: none;
  border-radius: 4px;
  background: #111a;
}

.view_listing .view_images.images_container .images img {
  height: 90%;
  object-fit: contain;
  min-width: 100%;
  pointer-events: none;
  border-radius: 4px;
  filter: drop-shadow(0px 0px 8px #1118);
  min-height: unset;
  max-width: 84%;
}

.view_listing .view_images.images_container .wishlist {
  display: none;
}

.view_listing .view_images.images_container .carousel_button {
  height: 70px;
  width: 70px;
  transform: unset !important;
  opacity: 0.8;
}

.view_listing .view_images.images_container .carousel_button.prev {
  left: 30px;
}

.view_listing .view_images.images_container .carousel_button.next {
  right: 30px;
}

.view_listing .view_images.images_container .slides {
  opacity: 1 !important;
}

.view_listing .view_images.images_container .slides .dot {
  height: 8px;
  width: 8px;
}

.view_listing .view_images.images_container .close {
  color: white;
  background: none;
  border: none;
  position: fixed;
  right: 16px;
  top: 16px;
  z-index: 1000;
  cursor: pointer;
  opacity: 70%;
  border-radius: 50%;
  transition: all 0.1s var(--bc);
  display: block;
}

.view_listing .view_images.images_container .close:hover {
  opacity: 1;
}

.view_listing .view_images.images_container .close svg {
  fill: white;
  height: 40px;
  width: 40px;
}

.view_listing .posted_on {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -4px 0;
}

.view_listing .posted_on p {
  display: flex;
  gap: 4px;
  align-items: center;
  color: #333;
  font-size: 13px;
  font-weight: 400;
  margin: -4px 0;
}

.view_listing .posted_on p svg {
  fill: var(--blue);
  height: 22px;
  width: 22px;
  stroke: white;
  stroke-width: 0.2;
  display: none;
}

.view_listing .posted_on span {
  font-weight: 500;
  color: black;
}

.no_user_message {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  gap: 4px;
}

.view_listing a {
  color: var(--blue);
  display: flex;
  align-items: center;
}

.view_listing .business_main a {
  font-size: 15px !important;
  color: #555;
}

.view_listing a svg {
  fill: var(--blue);
}

.view_listing .description pre {
  max-width: 100%;
  /* word-wrap: break-word; */
  overflow: hidden;
  margin: 0;
  font-family: var(--font2);
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  line-break: strict;
  text-wrap: wrap;
  line-height: inherit;
  font-size: 14px;
}

.view_listing .actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.view_listing .preview h1 {
  /* font-size: 20px;
  color: #444; */
}

.view_listing .actions .continue_editing {
  background: transparent;
  color: var(--blue);
  border: 1px solid var(--blue);
  flex: 1;
  height: 50px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 6px;
  gap: 2px;
  padding: 0 20px;
  min-width: fit-content;
}

.view_listing .tile.preview {
  position: fixed;
  bottom: 0px;
  z-index: 100;
  width: 100%;
  left: 0;
  box-shadow: 0px 0px 16px #1114;
}

.view_listing .actions .postAd {
  flex: 1 1;
  height: 50px;
  font-size: 20px;
  font-family: var(--font1);
  background: linear-gradient(
    90deg,
    var(--blue),
    var(--blue),
    var(--blue-dark)
  );
  position: relative;
  overflow: visible;
  /* background-color: var(--blue-dark); */
}
.view_listing .actions .postAd:before,
.view_listing .actions .postAd:after {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  background: linear-gradient(
    45deg,
    #fb0094,
    var(--blue),
    #00ff00,
    var(--yellow),
    #d400ff,
    var(--blue),
    #fb0094,
    var(--blue),
    #00ff00,
    var(--yellow),
    #d400ff
  );
  border-radius: 5px;
  background-size: 500%;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  z-index: -1;
  animation: steam 30s linear infinite;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 500% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.view_listing .actions .postAd:after {
  filter: blur(12px);
}

.view_listing .actions .continue_editing:hover {
  color: white;
  background-color: var(--blue);
}

@keyframes cta_btn {
  0% {
    background-position: top left;
  }

  90% {
    background-position: bottom right;
  }

  100% {
    background-position: top left;
  }
}

.view_listing .path {
  top: 134px;
  position: absolute;
  width: 80%;
  margin: 0 10%;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: start;
  color: #555;
  font-weight: 500;
  gap: 12px;
}

.view_listing hr {
  border: 3px solid #777;
  border-radius: 50%;
}

.view_listing .business_main {
  padding: 0;
  display: flex;
  align-items: start;
  gap: 12px;
}

.view_listing .business a svg {
  height: 18px;
  width: 18px;
}
.view_listing .business_main img {
  height: 62px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px #1113;
  object-fit: contain;
  max-width: 100px;
}

.view_listing .business_main .info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 100%;
  flex: 1;
  width: 20%;
}

.view_listing .business_main .info h3 {
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.view_listing .business_main .info p {
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #555;
}

.view_listing .business .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 20px;
}

.view_listing .expand {
  margin: -4px;
  transition: all 0.2s var(--bc);
  margin-left: auto;
}

.view_listing .extra_fields,
.view_listing ._desc {
  max-height: 296px;
  overflow: hidden;
  transition: all 0.2s var(--bc);
}
.view_listing .expand svg {
  height: 24px;
  width: 24px;
}
.view_listing .business .row a {
  gap: 4px;
}
.view_listing .business_main .info .row {
  display: flex;
  align-items: center;
  gap: 12px;
}

.view_listing .business a {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 15px;
  cursor: pointer;
  font-weight: 500;
  font-family: var(--font1);
}

.view_listing .youtube_website > div {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
}

.view_listing .youtube_website > div svg {
  height: 20px;
  width: 20px;
  fill: var(--blue);
}

.view_listing .youtube_website > div p {
  align-items: center;
  display: flex;
  gap: 4px;
  font-size: 14px;

  cursor: pointer;
  transition: all 0.3s var(--bc);
}

.view_listing a {
  color: inherit !important;
}

.view_listing .youtube_website > div p:hover {
  color: var(--blue);
}
._success {
  overflow: hidden;
  padding: 40px;
}
._success > div {
  height: 680px !important;
  width: 680px !important;
  margin: -180px -80px !important;
  overflow: hidden;
}
._success > p {
  font-size: 26px;
  color: #333;
  font-weight: 500;
  margin: 0;
  background-color: white;
  z-index: 10;
  text-align: center;
  position: relative;
  font-family: var(--font1);
}

.view_listing .ad_header {
  height: 48px;
  margin-top: -12px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 6px;
  font-weight: 600;
  font-size: 16px;
  font-family: var(--font1);
}

.view_listing .ad_header .back {
  background: none;
  border: none;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  font-size: 55px;
  min-height: 42px;
  min-width: 42px;
  color: var(--blue);
  margin: -6px;
}
.view_listing .tax {
  display: inline;
  padding-left: 4px;
  /* font-weight: 500;
  color: var(--blue) !important; */
  font-family: var(--font1);
  font-size: 13px;
}

.view_listing .country_img_global {
  margin-left: auto;
}

._gallery {
}

._gallery .images_container {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background: black;
}

._gallery .slides {
  display: none;
}
._gallery .images {
  width: 100%;
  max-height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: -moz-fit-content;
  height: fit-content;
  object-fit: contain;
  position: relative;
  min-height: 100vh;
  top: 0;
}

.view_listing p.category {
  font-size: 13px;
  overflow: HIDDEN;
  text-overflow: ELLIPSIS;
  white-space: NOWRAP;
  gap: 2px;
  width: 100%;
  margin: -4px 0 0;
}
.view_listing p.category svg {
  color: var(--blue);
  fill: var(--blue);
  height: 18px;
  width: 18px;
  margin: -3px;
  vertical-align: MIDDLE;
  padding: 0px;
  position: RELATIVE;
  top: -1px;
}
@media (min-width: 820px) {
  .view_listing {
    padding: 12px 24px;
  }
  .view_listing .main {
    flex-direction: row;
    flex-wrap: wrap;

    gap: 16px !important;
  }
  ._left {
    width: 60%;
    flex: 1;
    min-width: 55% !important;
    gap: 16px !important;
  }
  ._right {
    width: 36%;
    flex: 1;
    max-width: 600px;
    gap: 16px !important;
  }
  .view_listing .tile.preview {
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
  }
}
@media (min-width: 1600px) {
  .view_listing {
    padding: 0 10% 50px;
  }
}
