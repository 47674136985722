.payment_form .bal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
}

.payment_form {
  width: 100%;
  padding: 0 16px 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: start;
  align-items: start;
  height: calc(100vh - 80px);
  min-height: calc(100vh - 80px);
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 100px;
}
.payment_form *::-webkit-scrollbar,
.payment_form::-webkit-scrollbar {
  width: 8px;
  display: none !important;
}

.payment_form *::-webkit-scrollbar-track,
.payment_form::-webkit-scrollbar-track {
  background: var(--bg);
}

.payment_form *::-webkit-scrollbar-track,
.payment_form::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 10px;
  /* border: 3px solid var(--blue); */
}

.payment_form .left,
.payment_form .right {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 300px;
}

.billing_details .saved > div {
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.billing_details .saved p {
  margin: 0;
  font-size: 14px;
}
.billing_details .saved ._checkbox {
  flex-shrink: 0;
  height: 24px;
  width: 24px;
}
.billing_details .saved {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  box-shadow: 1px 1px 4px #ccc, -1px -1px 4px #ccc;
  padding: 16px;
  animation: 0.15s open forwards var(--bc);
  padding-right: 12px;
}
.billing_details .an {
  animation: 0.3s open forwards var(--bc);
}
.billing_details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* animation: 0.3s open forwards var(--bc); */
}
.billing_details .add {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  padding: 12px;
  box-shadow: 1px 1px 4px #ccc, -1px -1px 4px #ccc;
  animation: 0.15s open forwards var(--bc);
  height: fit-content;
}
.billing_details .add .line {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.billing_details .add .line:last-child {
  flex-direction: row;
}
.billing_details .add .line input {
  height: 100%;
  filter: drop-shadow(0px 0px 4px #1112);
  font-size: 14px;
  font-family: var(--font2);
  color: black;
  font-weight: 500;
  padding: 12px;
}
.billing_details .add .line ._dropdown {
  filter: drop-shadow(0px 0px 4px #1114);
  height: 18px;
  font-size: 16px;
  padding: 12px;
}
.billing_details p.save_for_later {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  margin: 0;
  font-family: var(--font1);
}
.billing_details .options.active {
  padding: 0;
  top: 18px;
  overflow-x: hidden;
  width: calc(100% + 28px);
}
.billing_details .option {
  font-size: 15px !important;
  padding: 10px !important;
}
.billing_details button.del {
  height: 24px;
  width: 24px;
  padding: 0;
  display: flex;
  border-radius: 3px;
  border: none;
  background: var(--blue);
  filter: drop-shadow(0px 0px 4px #1114);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.billing_details .del svg {
  fill: white;
}

.billing_details .saved span {
  display: flex;
  gap: 4px;
}
.payment_form h1 {
  font-size: 17px;
  font-weight: 500;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-top: 24px;
}

.payment_form svg {
  fill: var(--blue);
  height: 22px;
  width: 22px;
}

.payment_form ul {
  list-style: none;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.payment_form ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 20px;
  padding: 16px;
  height: 80px;
  box-sizing: border-box;
  border: 1px solid #eee;
}

.payment_form ul li h4 {
  font-weight: 500;
  color: #333;
  margin: 0;
}

.payment_form ul li p {
  margin: 0;
  font-size: 16px;
  color: #555;
}

.payment_form .total {
  display: flex;
  align-items: center;
  justify-content: end;
  color: #555;
  font-size: 18px;
  font-weight: 500;
  gap: 4px;
  /* padding: 0px 10px; */
}

.payment_form .price {
  color: var(--blue);
  font-size: large;
  font-weight: 600;
}

.payment_form .total .price {
  font-size: 20px;
}

.payment_form button {
  width: fit-content;
  margin-left: auto;
  background-image: linear-gradient(90deg, var(--blue), var(--blue-dark));
  padding: 12px 20px;
  border-radius: 4px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font1);
  box-shadow: 0px 0px 8px #1112;
}

.payment_form .package {
  border-radius: 4px;
  box-shadow: 1px 1px 4px #ccc, -1px -1px 4px #ccc;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.payment_form .package_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  gap: 50px;
}

.payment_form h4 {
  font-weight: 500;
  margin: 0;
}

.payment_form h3 {
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}

.payment_form .package_info span {
  font-size: 18px;
  font-weight: 600;
  color: var(--blue);
  font-family: var(--font2);
}

.payment_form .empty {
  background: linear-gradient(90deg, #1111, #1111, #1112);
  background-size: 300%;
  background-position: left center;
  border-radius: 4px;

  animation: skeleton 1s var(--bc) forwards infinite;
}

@keyframes skeleton {
  0% {
    background-position: left center;
  }

  90% {
    background-position: right center;
  }

  100% {
    background-position: left center;
  }
}

.payment_form span.empty {
  height: 32px;
  width: 80px;
}

.payment_form h4.empty {
  height: 24px;
}

.payment_form .price.empty {
  height: 36px;
  width: 200px;
}

.payment_form .stripe_payment,
.payment_form .pay_with_balance {
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: 8px;
  box-sizing: border-box;
  box-shadow: 1px 1px 4px #ccc, -1px -1px 4px #ccc;
  background-color: white;
}

.payment_form .pay_with_balance h4,
.payment_form .stripe_payment h4 {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 0;
  gap: 4px;
}

.payment_form .pay_with_balance svg {
  fill: var(--blue);
}

.payment_form .pay_with_balance h4 .mobile_checkbox,
.payment_form .stripe_payment h4 .mobile_checkbox {
  margin-left: auto;
}

.payment_form .or {
  font-weight: 500;
  text-align: center;
  font-size: 20px;
}

.payment_form .password {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  box-shadow: inset 0px 0px 6px #ccc;
  border-radius: 4px;
  padding: 16px;
  box-sizing: border-box;
  font-size: 17px;
  height: 56px;
  margin-top: 16px;

  transform: scaleY(0);
  transform-origin: top center;
  opacity: 0;
  animation: 0.3s open forwards var(--bc);
}

@keyframes open {
  0% {
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top center;
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: top center;
  }
}

.payment_form .password input {
  border: none;
  flex: 1;
  outline: none !important;
  font-size: 18px;
}

.payment_form .password span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  transition: all 0.3s var(--bc);
  margin-right: -12px;
}

.payment_form .bal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  margin: 20px 0 10px;
  animation: 0.3s open forwards var(--bc);
  transform-origin: top center;
}

.payment_form .bal .price {
  font-size: 28px;
}

.payment_form .bal h5 {
  font-weight: 500;
  margin: 0;
}
.billing_details h2 {
  margin: 0;
  color: var(--blue);
  font-weight: 500;
  font-size: 22px;
}

.payment_form .details_loading {
  height: 100vh !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment_form .lds-ring {
  transform: scale(1) !important;
}
@media (min-width: 800px) {
  .payment_form {
    flex-direction: row !important;
    padding: 24px !important;
    gap: 16px;
  }
}
