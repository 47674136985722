.mobile_loader_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.mobile_loader_container .loader {
  margin: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px solid #ffffff;
  border-top-color: var(--blue);
  animation: spin 1s infinite linear;
}


.mobile_loader_container .loader_title {
  font-size: 15px;
  font-weight: 500;
  line-height: 2;
  color: #444;

}

