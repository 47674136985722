.html_textarea_mobile {
  width: 100%;
  border: 1px solid transparent;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: 10px 5px 0px 10px;
  box-sizing: border-box;
  align-items: center;
  filter: drop-shadow(0px 0px 8px #ccc);
  gap: 5px;
  position: relative;
  flex: 1;
  outline: none !important;
  background-color: #fff;
}

.html_textarea_mobile textarea {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  resize: none;
  font-size: 16px;
  font-family: var(--font2);
  height: 250px;
}

/* Works on Chrome, Edge, and Safari */
.html_textarea_mobile textarea::-webkit-scrollbar {
  width: 6px;
  display: block !important;
}

.html_textarea_mobile textarea::-webkit-scrollbar-track {
  background: var(--bg);
}

.html_textarea_mobile textarea::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 10px;
}

.html_textarea_mobile:focus {
  border: 1px solid var(--blue);
}
