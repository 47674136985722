.contact_us_page_mobile {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contact_us_page_mobile .heading {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-top: 15px;
}

.contact_us_page_mobile .mobile_input_label {
  margin-bottom: -14px;
}

.contact_us_page_mobile .error_message {
  color: var(--red);
  margin-top: -8px;
  font-size: 14px;
}
