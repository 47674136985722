._autocomplete {
  display: flex;

  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  width: 100%;

  position: relative;
  padding: 0 12px;
}

._autocomplete button.clear {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: TRANSLATEY(-50%);
  height: 25px;
  width: 25px;
  font-size: 14px;
  border-radius: 5px;
  background: #2196f3;
  border: none;
  box-shadow: 0px 0px 4px #1114;
}
._autocomplete button.clear svg {
  height: 20px;
  width: 20px;
  color: white;
}
._autocomplete input {
  width: 100%;
  height: 42px !important;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  padding: 0px 16px;
  padding-right: 40px;
  box-sizing: border-box;
  align-items: center;
  gap: 5px;
  position: relative;
  box-sizing: border-box;
  border: none;
  flex: 1;
  outline: none !important;
  font-size: 16px;
  filter: drop-shadow(0px 0px 4px #1115);
  font-family: var(--font2);

  transition: all 0.3s var(--bc);
  border: 1px solid var(--blue);
}

._autocomplete ul {
  position: absolute;
  background: white;
  filter: drop-shadow(0px 0px 4px #1115);
  width: calc(100% - 24px);
  box-sizing: border-box;
  padding: 0px;
  list-style: none;
  display: flex;
  top: 46px;
  flex-direction: column;
  margin: 0;
  border-radius: 5px;
  z-index: 100;
  /* width: 100%; */
  margin: auto;
  left: 12px;

  overflow: hidden;
}

._autocomplete ul li {
  font-size: 14px;
  height: 36px;

  align-items: center;
  cursor: pointer;
  transition: all 0.3s var(--bc);
  padding: 12px;

  gap: 8px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  box-sizing: border-box;
}

._autocomplete ul li:hover {
  background-color: #1111;
}
