.business_info {
  width: 100%;
  padding: 10px;
  margin: auto;
  margin-top: -6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  overflow-y: scroll;
  gap: 8px;
  max-width: 600px;
}

.business_info .field_container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: start;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px #1114;
  flex-direction: column;
}

.business_info .field_container.image {
  flex-direction: row;
}

.business_info input,
.business_info ._dropdown,
.business_info .custom_price_input {
  /* border: 1px solid #fff; */
  transition: all 0.3s var(--bc);
  height: 38px;
  max-height: 38px;
  font-size: 14px;
  padding: 12px;
  width: 100%;
}

.business_info .custom_price_input input {
  border: none !important;
}

.business_info .field_info p {
  color: #777 !important;
  font-weight: 500;
  margin: 0;
}

.business_info h4 span,
.business_info h2 span {
  font-size: 12px;
  color: #777;
  font-weight: 500;
  margin-right: 0;
}

.business_info h4 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  font-family: var(--font2);
  color: #333;
  display: flex;
  gap: 4px;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  width: 100%;
}
.business_info h4 svg {
  fill: var(--blue);
}

.business_info h2 {
  text-align: center;
  font-weight: 500;
  margin: 0;
  margin-bottom: 0px;
  font-size: 28px;
}

.business_info .actions {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 12px;
  margin-bottom: 60px;
}
.business_info .actions button {
  width: fit-content;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  flex: 1;
}

.business_info .content {
  flex: 1;
}

.business_info .image_input {
  height: 100px;
  max-width: 200px;
  border-radius: 6px;
  min-width: 100px;
  overflow: hidden;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #555 !important;
  border: 1px solid var(--blue);
  transform: all 0.3s var(--bc);
  position: relative;
  background-color: #2196f322;
}
.business_info .image_input:hover {
  border: 1px solid var(--blue);
}

.business_info .image_input p {
  padding: 20px;
  margin: 0;
  font-size: 14px;
  color: black;
}

.business_info .image_input img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.business_info .delete {
  position: absolute;
  top: 2px;
  right: 2px;
  background: #444c;
  color: white;
  border: none;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 8px #1118;

  transition: all 0.3s var(--bc);
  cursor: pointer;
}

.business_info .delete svg {
  height: 20px;
  width: 20px;
}

@media (max-width: 380px) {
  .business_info .field_container {
    flex-direction: column;
    gap: 8px;
  }
  .business_info .field_container .field_info {
    width: 100%;
    max-width: 100%;
  }
}
