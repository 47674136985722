.mobile_input_label {
  font-size: 15px;
  font-weight: 500;
  line-height: 2;
  color: #444;
}

.mobile_input_label .required {
  font-size: 13px;
  color: #757575;
  font-weight: 400;
}

.___app {
  width: 100%;
  min-height: 100vh;
  min-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 1200px;
  overflow-x: hidden;
  min-height: 100vh;
  position: absolute;
  background-color: white;
  font-family: var(--font2);
  /* padding-bottom: 66px; */
}
body::-webkit-scrollbar,
* ::-webkit-scrollbar {
  display: none;
}

.mobile_input_info {
  font-size: 12px;
  color: var(--gray);
}

span.ripple_fast {
  position: absolute;
  border-radius: 50%;
  /* To make it round */
  top: 0;
  left: 0;
  background-color: #0002;

  width: 30vw;
  height: 30vw;
  /* margin-top: -50%;
  margin-left: -50%; */
  transform: translate(-50%, -50%);

  opacity: 0;
  animation: ripple 0.5s linear;
}
span.ripple {
  position: absolute;
  border-radius: 50%;
  /* To make it round */
  top: 0;
  left: 0;
  background-color: #0002;

  width: 20vw;
  height: 20vw;
  /* margin-top: -50%;
  margin-left: -50%; */
  transform: translate(-50%, -50%);

  opacity: 0;
  animation: ripple 0.5s linear;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }

  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(12);
  }
}

.___app svg {
  pointer-events: none;
}

.btn_classic {
  padding: 16px;
  font-size: 17px;
  font-weight: 500;
  color: white;
  background-color: var(--blue);
  box-shadow: 0px 0px 8px #1118;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font2);
  border: none;
  height: 40px;
  box-sizing: border-box;
}

svg {
  height: 24px;
  width: 24px;
}
.not_found_animation {
  width: 100%;

  outline: none;
  margin-top: -80px;
  border: none;
  background: white;
  z-index: 1000;
  height: 40vh;
  position: relative;
}
._not_found {
  margin: 0;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  font-family: var(--font1);
  margin-top: -40px;
  z-index: 1000;
  position: relative;
}
