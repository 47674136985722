.mobile_footer {
  text-align: center;
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 6px;
  align-items: center;
  left: 50%;
  color: #fff;
  bottom: 0;
  width: calc(100% - 16px);
  box-sizing: border-box;
  box-shadow: 0px 0px 8px #1116;
  background-color: var(--blue);
  z-index: 333;
  max-width: 600px;
  margin: 8px auto;
  border-radius: 36px;
  transition: all 0.5s var(--bc), opacity 0.2s var(--bc);
  transform-origin: bottom center;
  transform: translate(-50%, 80px) scale(0.6);
  opacity: 0;
  /* bottom: -60px; */
}
.mobile_footer.visible {
  transform: translate(-50%, 0) scale(1);
  opacity: 1;

  transition: 0.2s var(--bc);
}

.mobile_footer .item_cont {
  text-align: center;
  text-decoration: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  /* border-radius: 50%; */
  box-sizing: border-box;

  max-width: 60px;
  max-height: 60px;
  width: 7vh;
  height: 7vh;
  min-width: 50px;
  min-height: 50px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 4;
}

.mobile_footer .item_cont svg {
  height: 4vh;
  width: 4vh;
  max-height: 36px;
  max-width: 36px;
  transition: all 0.2s var(--bc);
  min-width: 30px;
  min-height: 30px;
}
.mobile_footer .item_cont .home {
  height: 3.8vh !important;
  width: 3.8vh !important;
  max-height: 33px;
  max-width: 33px;
}
.mobile_footer .item_cont .profile {
  height: 4.2vh !important;
  width: 4.2vh !important;
  max-height: 39px;
  max-width: 39px;
}
.mobile_footer svg.mid {
  height: 6vh;

  width: 6vh;
  max-height: 50px;
  max-width: 50px;
}

.mobile_footer .item_cont.active svg {
  /* background-color: white; */
  transition-delay: 0.1s;
  color: var(--blue) !important;
}

.mobile_footer .mid img {
  height: 100%;
  position: absolute;
  z-index: -1;
  margin-left: 4px;
}

.mobile_footer .indicator {
  position: absolute;
  background: white;

  top: 6px;
  left: 0;

  border-radius: 50%;
  transform: translateX(6px);
  transition: all 0.3s var(--bc);
  max-width: 60px;
  max-height: 60px;
  width: 7vh;
  height: 7vh;
  min-width: 50px;
  min-height: 50px;
}
