.post_ad_page {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.relist_ad {
  display: block;
  flex-direction: column;
  overflow-y: scroll;
  height: 80vh;
  min-height: 600px;
  padding: 0;
  width: 1300px;
  min-width: 1300px;
}
.relist_ad::-webkit-scrollbar {
  width: 8px;
  display: block !important;
}

.relist_ad::-webkit-scrollbar-track {
  background: var(--bg);
}

.relist_ad::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 10px;
  /* border: 3px solid var(--blue); */
}

.relist_ad .ad_info_cont {
  width: 100%;
}
.relist_ad .ad_info {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  box-shadow: 1px 1px 8px #1112, -1px -1px 8px #1112;

  box-sizing: border-box;
  overflow: hidden;
}

.relist_ad .ad_info img {
  width: 25%;
  min-width: 300px;
  height: 160px;
  object-fit: cover;
}
.relist_ad .ad_info > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 70%;
  flex: 1 1;
  min-width: 600px;
  height: 100%;
  padding: 10px 20px 10px 10px;
}
.relist_ad .ad_info p {
  margin: 0;
}
.relist_ad .ad_info > div h4 {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.relist_ad .ad_info_row,
.relist_ad .ad_info_row > p {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: #777;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
.relist_ad .ad_info_row span {
  display: flex;
  align-items: center;
  color: #444;
}
.relist_ad .ad_info .line {
  margin: 0;

  margin: 0;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  flex: 1;
}

.relist_ad .ad_info h4.line {
  font-size: 20px;
  font-weight: 500;
}

.relist_ad .ad_info .price {
  color: var(--blue);
  font-size: 24px;
  font-weight: 600;
  font-family: var(--font1);
}

.relist_ad .line.location {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #555;
  gap: 2px;
  justify-content: start;
}

.relist_ad .line.location svg {
  fill: var(--blue);
}

.relist_ad .btns_cont {
  display: flex;
}
.relist_ad .ad_form {
  margin: auto;
  width: 100%;
}
