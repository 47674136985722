.confirm_dialog {
  padding: 40px 20px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.confirm_dialog .message {
  font-size: 18px;
  text-align: center;
  margin: 0 20px 40px;
  font-weight: 500;
}
.confirm_dialog .buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  position: relative;
  /* overflow: hidden; */
}
.confirm_dialog button {
  height: 48px;
  width: 100%;
  border: none;
  border-radius: 6px;
  background: var(--blue);
  font-family: var(--font1);
  color: white;
  font-size: 16px;
  box-shadow: 0px 0px 12px #1114;
  font-weight: 500;
  overflow: hidden;
  position: relative;
}
.confirm_dialog .cancel {
  border: 2px solid var(--red-dark);
  color: white;
  background-color: var(--red-dark);
}
