.post_ad {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: START;
  justify-content: START;
  position: absolute;
  box-sizing: border-box;
  top: 0px;
  left: 0;
}

.post_ad .main {
  width: 100%;
  padding: 8px;
  display: flex;
}

.post_ad .main .ad_form {
  background-color: WHITE;
  width: 100%;
  border-radius: 5px;
  padding: 0;
  position: relative;
  min-height: 90vh;
  box-sizing: border-box;
  padding-bottom: 80px;
}

.post_ad ._tags {
  min-width: 50%;
  gap: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.post_ad .tags_cont {
  display: flex;
  width: 100%;
  justify-content: start;
  gap: 8px;
  flex-wrap: wrap;
  filter: drop-shadow(0px 0px 4px #1114);
}
.post_ad .form_content {
  flex-wrap: wrap;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: Calc(100vw - 24px);
  margin: 12px auto 0;
  box-sizing: border-box;
}

.post_ad .container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 0px 10px;
  align-items: flex-start;
}

.post_ad .col_1 {
  flex: 35%;
  display: grid;
}

.post_ad .col_2 {
  flex: 65%;
}

.post_ad .row {
  width: 100%;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  box-sizing: border-box;
}

.post_ad .field {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.post_ad h4,
.price_cont h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}
.price_cont {
  flex-direction: column !important;
}

.post_ad .required {
  color: var(--gray);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.post_ad .flex_row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 10px;
  box-sizing: border-box;
}

.post_ad .flex_col {
  flex: 1;
}

.post_ad .flex_col:nth-child(1),
.flex_col:nth-child(2) {
  flex: 45%;
}

.post_ad .flex_col:nth-child(3) {
  flex: 20%;
}

.post_ad .btns_cont,
._btns_cont {
  min-width: 100%;
  display: flex;
  gap: 12px;
  flex-direction: row;
  width: 100%;
  justify-content: END;
  align-items: end;
  margin-top: 12px;
  position: fixed;
  background: white;
  padding: 16px 16px 12px;
  box-sizing: border-box;
  /* top: CALC(100% - 52px); */
  bottom: 0;
  left: 0;
  box-shadow: 0px 0px 8px #1114;
  z-index: 9999;
}

.post_ad .btns_cont .discard,
._btns_cont .discard {
  font-size: 16px;
  flex: 1;
  height: 48px;
  font-weight: 500;
  font-family: var(--font2);
  padding: 16px 20px;
  color: var(--red);
  border: 1px solid var(--red);
  border-radius: 5px;
  background-color: transparent;
  transition: all 0.33s var(--bc);
  box-shadow: 0px 0px 8px #1114;
  cursor: pointer;
  border-radius: 8px;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post_ad .btns_cont .next_btn,
._btns_cont .next_btn {
  font-size: 16px;
  width: fit-content;
  height: 48px;
  font-weight: 500;
  flex: 1;
  font-family: var(--font2);
  padding: 16px 20px;
  border-radius: 5px;
  max-width: 200px;
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.post_ad ._autocomplete {
  width: calc(100% + 24px);
  margin: 0 -12px;
}
.post_ad ._dropdown .options {
  min-height: auto;
  overflow: visible;
}

.post_ad ._ .field_info {
  gap: 8px;
}

.post_ad ._ ._dropdown .options {
  overflow-y: auto;
  max-height: fit-content;
  min-height: 200px;
}
.post_ad ._dropdown .options::-webkit-scrollbar {
  width: 8px;
  display: block !important;
}

.post_ad ._dropdown .options::-webkit-scrollbar-track {
  background: var(--bg);
}

.post_ad ._dropdown .options::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 10px;
  /* border: 3px solid var(--blue); */
}

.post_ad .field_container {
  display: flex;
  background-color: white;
  filter: drop-shadow(0px 0px 4px #1115);
  border-radius: 8px;
  gap: 20px;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  position: relative;
  padding: 16px;
  flex-direction: column;
  box-sizing: border-box;
}

.post_ad .pricing {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  transition: all 0.3s var(--bc);
  max-height: 600px;
  overflow: hidden;
}
.post_ad .pricing.hidden {
  max-height: 0;
}

.post_ad .pricing_type,
.post_ad .tax_cont,
.post_ad .ad_type_container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  filter: drop-shadow(0px 0px 4px #1114);
  flex: 1;
  min-width: max(50%, 300px);
  max-width: 100%;
}
.post_ad .ad_type_container {
  margin-top: 8px;
  min-width: max(50%, 300px);
  flex: 1;
  width: 100%;
}
.post_ad .pricing_type .type,
.post_ad .tax_cont .tax,
.post_ad .ad_type_container .ad_type {
  flex: 1;
  padding: 8px 16px;
  border: 1px solid var(--blue);
  border-radius: 5px;
  background: white;
  color: black;
  font-size: 14px;
  font-family: var(--font1);
  font-weight: 500;
  transition: all 0.2s var(--bc);
  text-align: center;
}
.post_ad .pricing_type .type,
.post_ad .ad_type_container .ad_type {
  padding: 10px;
  font-size: 15px;
}
.post_ad .pricing_type .type {
  flex: 1 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.post_ad .pricing_type .type.active,
.post_ad .tax_cont .tax.active,
.post_ad .ad_type_container .ad_type.active {
  background-color: var(--blue);
  color: white;
}
.post_ad .p_field {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.post_ad .pricing p.pricing_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #555 !important;
  font-size: 16px;
  font-weight: 500;
  min-width: fit-content;
}
.post_ad .field_container .field_info {
  width: 100%;
  box-sizing: border-box;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.post_ad .form .field_info {
  gap: 4px;
}
.post_ad .form .field_info h4 {
  font-size: 14px;
}
.post_ad .field_container .input-container,
.post_ad .field_container .dropdowns {
  width: 100%;

  flex: 1;
  gap: 12px !important;
}
.post_ad input,
.post_ad ._dropdown,
.post_ad .custom_price_input {
  /* border: 1px solid #fff; */
  transition: all 0.3s var(--bc);
  filter: drop-shadow(0px 0px 4px #1114);
  background-color: white;
  height: 50px;
}

.post_ad .custom_price_input input {
  border: none !important;
}

.post_ad .field_container p {
  color: #555 !important;
  font-weight: 400;
  margin: 0;
  font-size: 13px;
}

.post_ad h4 span,
.post_ad h2 span {
  color: var(--blue);
  font-size: 12px;
  font-weight: 500;
  vertical-align: baseline;
}

.post_ad h2 {
  font-weight: 600;
  margin: 0;
  text-align: center;
  font-size: 18px;
  display: flex;
  gap: 6px;
  font-family: var(--font2);
  align-items: center;
  justify-content: center;
}
.post_ad h2 svg {
  fill: var(--blue);
  height: 26px;
  width: 26px;
}
.package_icon {
  fill: transparent !important;
  stroke: var(--blue) !important;
  stroke-width: 2;
}
.post_ad .field_container ._dropdown {
  flex: 1;
  max-height: 28px;
}
.pricing_section .add_on .addon_select {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: start;
  align-items: center;
  filter: drop-shadow(0px 0px 4px #1115);

  min-width: 100%;
}
.pricing_section .addon_select div {
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid var(--blue);
  background: white;
  font-size: 15px;
  font-family: var(--font1);
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 0.2s var(--bc);
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.pricing_section .addon_select div span {
  font-weight: 600;
  color: var(--blue);
  font-size: 18px;
}

.post_ad .step3 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
}

.post_ad ._dropdown .selected {
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  font-weight: 500;
}

/* .post_ad .dropdowns */
.post_ad .field_container .dropdowns {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.post_ad ._dropdown hr {
  display: none;
}

.post_ad ._dropdown .option {
  padding: 12px 16px;
  justify-content: start;
  font-size: 18px;
}

.post_ad .tag_inp {
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  flex: 1 1;
  overflow: hidden;
  filter: drop-shadow(1px 1px 4px #1113);
  background: white;
  border-radius: 5px;
  gap: 6px;
  border: 1px solid var(--blue);
  transition: all 0.3s var(--bc);
  padding: 6px 8px;

  box-sizing: border-box;
  display: flex;
  justify-content: start;
  align-items: center;
  overflow-x: scroll;
  width: 100%;
}

.post_ad button.add_tag {
  background: var(--blue);
  padding: 12px;
  max-height: 40px;
  max-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: navajowhite;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
}

.post_ad .tag_inp input {
  min-height: unset;
  font-size: 15px;
  flex: 1;
  width: 0;
  border: none !important;
  outline: none !important;

  filter: none;
}

.post_ad .tag {
  border-radius: 5px;
  padding: 4px 8px;
  padding-left: 16px;
  background: #2196f3;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;
  box-sizing: border-box;
  cursor: pointer;
  color: white;
  border: 1px solid var(--blue);
}
.post_ad .tag svg {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 1px;
}
.post_ad ._autocomplete inpu {
  height: 42px !important;
}

.desc_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 100%;
}

.post_ad .field_container > .options {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: start;
  filter: drop-shadow(0px 0px 4px #1114);
}
.post_ad .field_container .radio_option {
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid var(--blue);
  color: black;
  font-size: 15px;
  cursor: pointer;
  background-color: white;
}

.post_ad .field_container .radio_option.active {
  background-color: var(--blue);
  border-color: var(--blue);
  color: #fff;
}

.post_ad .tag pre {
  font-family: inherit !important;
  font-size: inherit !important;
  margin: 0;
  padding: 0;
}

.post_ad .tag_inp::-webkit-scrollbar {
  display: none;
}

.post_ad .location {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-direction: column;
}

.post_ad .selections {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  gap: 12px;
  padding-top: 8px;
}

.post_ad .map {
  height: 25vh;
  min-height: 200px;
  display: flex;
  border-radius: 5px;
  filter: drop-shadow(0px 0px 4px #1115);
  border-radius: 5px;
  overflow: hidden;
  /* flex: 1; */
}

.post_ad .use_current_location {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 100;
  transform: translate(0%, -50%);
  border: none;
  background: transparent;
  color: var(--blue);
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  background: white;
}

.post_ad .map.hidden {
  pointer-events: none;
  position: relative;
  overflow: hidden;
}
.post_ad .map.hidden > div {
  /* filter: blur(4px); */
}
.post_ad .map.hidden::before {
  align-items: center;
  justify-content: center;
  content: "Select a location to view map";
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  background: transparent;
  width: 100%;
  /* margin: 0 15%; */
  z-index: 1000;
  display: flex;
  /* transform: translateX(50%); */
  /* color: var(--blue); */

  font-size: 16px;
  font-weight: 500;
  filter: none;
  text-align: center;
}

.ad_form .free {
  color: #777;
  margin-right: auto;
  flex: 1;
  display: block;
}
.post_ad .location_option {
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.ad_form .current_loc p {
  color: #444;
  font-weight: 500;
  min-width: fit-content;
}

.ad_form .heading {
  margin: 0;
  font-weight: 500;
}

.ad_form .current_loc {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
  margin-top: -8px;
  height: 40px;
}

.ad_form .current_loc .text_box p {
  margin: 0px;
  color: var(--blue);
  font-size: 15px;
  font-weight: 500;
}

.ad_form .current_loc .text_box .curr_loc_text {
  font-size: 12px;
  font-weight: 400;
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ad_form .curr_icon {
  height: 25px;
  width: 25px;
  fill: var(--blue);
}

.pricing_section h3 {
  display: flex;
  align-items: center;
  gap: 6px;
}

.pricing_section p.free_text {
  font-weight: 500;
  font-size: 14px !important;
  margin: -8px !important;
  margin-bottom: 2px !important;
}

.pricing_section span.fr {
  font-size: 28px;
}
.pricing_section button.select_package p {
  margin: 0;
  font-size: 16px;
  margin-bottom: 4px;
}

.pricing_section button.select_package.Basic {
  background-image: linear-gradient(45deg, #777, #555);
}

.pricing_section button.select_package.Premium {
  background-image: linear-gradient(45deg, var(--yellow), #d5961f);
}

.pricing_section .add_ons {
  display: flex;
  flex-direction: column-reverse;

  border-radius: 5px;
  gap: 16px;
}
.pricing_section .add_on {
  display: flex;
  width: 100%;
  height: fit-content;
  background: white;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  gap: 16px;
  min-height: 72px;
  border-radius: 5px;
  flex-wrap: wrap;
  filter: drop-shadow(0px 0px 4px #1115);
  justify-content: space-between;
}
.pricing_section .add_on .addon_select div.selected {
  background-color: var(--blue);
  color: white !important;
}
.pricing_section .addon_select div.selected span {
  color: white;
}
.pricing_section .add_on h3 {
  font-weight: 600;
  font-size: 16px;
  flex-grow: 1;
  margin: 0;
  min-width: 50%;
  font-family: var(--font1);

  /* margin-right: auto; */
}

.pricing_section .add_on ._info {
  margin-left: auto;
}

.pricing_section .add_on span.price {
  font-size: 20px;
  font-weight: 600;
  color: var(--blue);
  /* margin: 20px; */
  font-family: var(--font1);
  width: 100px;
  text-align: right;
}

.pricing_section .add_on ._dropdown {
  filter: drop-shadow(0px 0px 4px #ccc);
  padding: 6px 12px;
  padding-right: 0;
  width: 100px;
}

.pricing_section .add_on ._dropdown .options {
  top: 12px;
}
.pricing_section .add_on ._dropdown .option {
  padding: 8px 16px;
  font-size: 16px;
}

.pricing_section .extra .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  border-radius: 5px;
  box-sizing: border-box;
}

.pricing_section .extra .info h3 {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font1);
  margin: 0;
}

.pricing_section .extra .info .price {
  margin: 0;
  margin-left: auto;
  font-size: 22px;
  color: var(--blue);
  font-family: var(--font1);
  font-weight: 600;
}

.pricing_section .extra .form {
  display: flex;
  justify-content: space-between;
  align-items: start;
  box-sizing: border-box;
  transition: all 0.1s var(--bc);
  transform: scaleY(0);
  opacity: 0;
  transform-origin: top center;
  height: 0;
  flex-direction: column;
  gap: 12px;
}
.pricing_section .extra .form.active {
  opacity: 1;
  transform: scaleY(1);
  padding: 20px 0 0px;
  height: fit-content;
}

.pricing_section .extra .form button {
  background-color: var(--blue);
  font-family: var(--font2);
  font-size: 16px;
  font-weight: 600;
  padding: 12px 16px;
  border: none;
  border-radius: 4px;
  color: var(--fg);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.33s var(--bc);
  box-shadow: 0px 0px 8px #1118;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pricing_section .no_business_info {
  color: var(--red);
  font-size: 14px;
  font-weight: 500;
}

.pricing_section .business_ov {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 18px;
  max-width: 100%;
  height: 52px;
  overflow: hidden;
}

.pricing_section .business_ov img {
  height: 100%;
  width: auto;
  max-width: 100px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0px 0px 8px #ccc;
}

.pricing_section .business_ov > div {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100%;
  gap: 0px;
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
}

.pricing_section .business_ov h4 {
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  width: 100%;
}

.pricing_section .business_ov p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  width: 100%;
  color: #333;
}

.pricing_section,
.location_section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0px 0;
  background: white;
  filter: drop-shadow(0px 0px 4px #1115);
  padding: 20px;
}

.extra .field_container {
  box-shadow: none !important;
  padding: 0;
}

.pricing_section .extra {
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  justify-content: center;
  height: -moz-fit-content;
  margin: 0;
  box-sizing: border-box;
  background: white;
  min-height: 60px;

  border-radius: 5px;
  filter: drop-shadow(0px 0px 4px #1115);
}

.pricing_section .input_container input {
  filter: none;
}
.ad_form span.free {
  font-weight: 500;
  font-size: 13px;
  width: 100%;
  display: BLOCK;
}

.ad_form .tax p {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  font-size: 13px;
}
.ad_form ._dropdown .selected > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.ad_form ._dropdown .option img,
.ad_form ._dropdown .option svg {
  max-height: 34px;
  width: 34px;
  margin: -4px;
  fill: black !important;
  object-fit: fill;
}
.ad_form ._dropdown .selected > div img,
.ad_form ._dropdown .selected > div svg {
  width: 36px;
  fill: black !important;
  object-fit: fill;
}

.post_ad ._ {
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  justify-content: space-between;
}

.post_ad ._ .field_container {
  display: flex;

  justify-content: space-between;
  align-items: start;
  position: relative;
  width: 100%;
}

.post_ad ._ .field_container .input_container {
  display: flex;
  flex: 1 1;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  width: 100%;
  max-height: 44px;
  border: 1px solid var(--blue);
  border-radius: 5px;
  height: 44px;
  min-height: 44px;
}

.post_ad ._ .field_container .input_container input {
  height: 100%;
  min-height: 100%;
  font-size: 14px;
}

.post_ad ._ ._dropdown {
  padding: 10px 16px;
  flex: 1;
  width: calc(100% - 32px);
}
.post_ad ._ ._dropdown .options {
  top: 16px;
  gap: 0;
}
.post_ad .pricing_section,
.post_ad .location_section {
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  background: white;
}

.post_ad ._price_inp {
  display: flex;
  /* flex-direction: column; */
  gap: 8px;
  flex: 1;
  flex-wrap: wrap;
  min-width: max(50%, 300px);
  justify-content: space-between;
}

.post_ad .price_hidden {
  display: flex;
  gap: 16px;
  align-items: start;
  font-size: 15px;
}
.post_ad ._pr_row {
  display: flex;
  min-width: 50%;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.post_ad .tax_op {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.post_ad .tax_op span,
.post_ad .tax_op p {
  flex: 1;
  width: fit-content;
  min-width: fit-content;
}
.post_ad .desc {
  flex-direction: column !important;
  align-items: start !important;
  justify-content: start;
  gap: 16px !important;
}
.post_ad ._ ._dropdown {
  min-width: 240px;
}

.relist_ad.post_ad {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: START;
  justify-content: START;
  position: static !important;
  overflow-y: scroll;
  padding: 12px;
  box-sizing: border-box;
  gap: 12px;
  /* position: absolute; */
  /* box-sizing: border-box; */
  /* top: 66px; */
  /* left: 0; */
  padding-bottom: 120px !important;
}

.relist_ad .ad_form {
  gap: 12px;
  display: flex;
  flex-direction: column;
}
.relist_ad.post_ad::-webkit-scrollbar {
  display: none !important;
}
.relist_ad {
  width: 100%;
  min-width: 300px !important;
}

@media (min-width: 820px) {
  .post_ad .pricing_type .type {
    font-size: 15px;
  }
  .post_ad .field_container .dropdowns {
    flex-direction: row;
  }
  .post_ad ._price_inp {
    flex-direction: row;
  }
  .post_ad .main {
    padding: 8px 0;
  }

  .post_ad ._price_inp .option_container {
    flex-grow: 1;
    width: unset;
  }

  .post_ad ._price_inp .custom_price_input {
    font-size: 26px;
  }
  .post_ad ._price_inp .symbol {
    font-size: 18px;
    padding: 8px;
  }
  .post_ad .field_container {
    padding: 24px;
  }
  .post_ad ._ {
    flex-wrap: wrap;
  }

  .post_ad ._ .field_container {
    flex: 1;
  }

  .pricing_section .add_on {
    padding: 24px;
  }
  .pricing_section .extra {
    padding: 24px;
  }
  .post_ad .custom_price_input,
  .post_ad .option_container {
    max-width: 50%;
    min-width: 50%;
  }
  .post_ad .location {
    flex-direction: row;
    margin-bottom: -20px;
    margin-top: 20px;
    gap: 40px;
    padding: 24px;
  }
  .post_ad h2 {
    font-size: 24px;
  }
  .post_ad .location .selections {
    width: 60%;
    top: -20px;
    position: relative;
  }
  .post_ad .location .map {
    min-width: 400px;
    width: 40%;
    position: relative;
    top: -20px;
  }
  .pricing_section .add_on ._info {
    margin: 0;
    margin-right: auto;
  }
  .post_ad .pricing_section,
  .post_ad .location_section {
    padding: 20px;
  }
  .pricing_section .extra .form.active {
    display: flex;
    align-items: end;
    justify-content: space-between;
    flex-direction: row;
  }
  .pricing_section .extra .form.active button {
    max-width: 400px;
  }
  .post_ad ._ {
    width: calc(100% - 36px);
    margin: 20px auto 0;
    gap: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  }
  .post_ad .form_content {
    margin: 20px auto 0;
    gap: 16px;
  }
  .post_ad h4,
  .price_cont h4 {
    height: fit-content;
  }
}
@media (min-width: 1000px) {
  .post_ad h4,
  .price_cont h4 {
    width: fit-content;
  }
  .post_ad .price_hidden {
    display: flex;
    gap: 16px;
    align-items: center;
    font-size: 15px;
    width: 50%;
  }
  .post_ad .field_container {
    flex-direction: row;
    align-items: center;
    gap: 40px;
    min-width: 50%;
  }
  .__stepp {
    display: flex;
    gap: 6px;
    width: calc(100vw - 36px);
    position: relative;
    filter: drop-shadow(0px 0px 4px #1115);
    top: 2px;
    left: 0px;
  }
  .post_ad ._ .field_container {
    flex-direction: column;
  }
  .post_ad .p_field {
    gap: 40px;
  }
  .post_ad .tag_inp {
    min-width: 50%;
  }
  .post_ad .field_container .dropdowns {
    min-width: 50%;
  }

  .post_ad ._pr_row {
    display: flex;
    min-width: 50%;
    flex-direction: column;
    gap: 12px;
  }
  .post_ad .tax_op {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .post_ad .tax_op span,
  .post_ad .tax_op p {
    flex: 1;
    width: fit-content;
    min-width: fit-content;
  }
  .post_ad .input_container {
    width: 50%;
    min-width: 50%;
  }
  .post_ad .field_info {
    height: 100%;
    align-self: stretch;
    justify-content: space-between;
    align-self: center;
  }
  .post_ad .field_container > .options {
    justify-content: start;
  }

  .post_ad.relist_ad {
    padding: 24px;
    max-width: 1200px;
    margin: AUTO;
  }
}

.ad_form ._price_inp,
.ad_form .tax_op {
  transform-origin: top;
  transition: all 0.1s var(--bc);
}
