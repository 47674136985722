.web_location .selections {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: calc(100vh - 48px);
  box-sizing: border-box;
  padding-top: 10px;
}
.web_location {
  width: 100%;
  height: 100%;
}

.web_location h4 {
  font-size: 16px;
  font-weight: 500;
  /* padding: 0 6px; */
}
.web_location .current_loc p {
  color: #333;
  font-weight: 500;
  /* min-width: fit-content; */
}
.web_location .rec {
  padding: 12px;
}
.web_location .recents {
  height: 0;
  max-height: 0;
  transform: scaleY(0);
  opacity: 0;
  transform-origin: top center;
  transition: all 0.2s var(--bc);
}
.web_location .recents.active {
  height: fit-content;
  max-height: fit-content;
  transform: scaleY(1);
  opacity: 1;
}

.web_location .heading {
  margin: 0;
  font-weight: 500;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.web_location .heading svg {
  transition: all 0.2s var(--bc);
}
.web_location .heading.active svg {
  transform: rotate(180deg);
}

.web_location .current_loc {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 6px;
  cursor: pointer;
  height: 38px;
  padding: 0 20px;
  padding-top: 4px;
}

.web_location .current_loc .text_box p {
  margin: 0px;
  color: var(--blue);
  font-size: 14px;
  font-weight: 500;
}

.web_location .current_loc .text_box .curr_loc_text {
  font-size: 14px;
  font-weight: 500;
  color: black;
  margin: 0;
  max-width: calc(100vw - 60px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.web_location .curr_icon {
  height: 24px;
  width: 24px;
  fill: var(--blue);
}

.web_location .regions {
  flex: 1 1;
  padding: 0;
  gap: 8px;
  display: flex;
  padding: 8px;
  flex-direction: column;
  overflow-y: hidden;
}

.web_location .provinces {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.web_location .regions .change_province {
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 14px;
  color: var(--blue);
  font-weight: 400;
  margin: 8px 0px;
  margin: 0;
}

.web_location .regions ::-webkit-scrollbar {
  display: none;
}

.web_location .regions .regions_container .region {
  display: flex;
  flex-direction: column;

  overflow: scroll;
}

.web_location .regions .regions_container .region div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 4px 10px;
  padding-right: 2px;
  cursor: pointer;
  transition: all 0.3s var(--bc);
  color: #333;
  font-size: 14px;
  display: flex;
  align-items: center;
  /* margin-right: 2px; */
  height: 40px;
  box-sizing: border-box;
  overflow: hidden !important;
  position: relative;
}

.web_location .regions .regions_container .region div p {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.web_location .regions .regions_container .region div svg {
  fill: #bcbcbc;
}

.web_location .location_error {
  color: red !important;
  font-size: 14px !important;
  margin: 0;
  margin-top: 5px;
  font-weight: 500 !important;
}

.web_location .recent_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.web_location .clear_all_btn {
  font-size: 14px;
  background: transparent;
  color: var(--blue);
  border-radius: 4px;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

.web_location ._autocomplete {
  display: block;
  padding: 0 12px;
}

.web_location .map,
.web_location .tile {
  filter: drop-shadow(0px 0px 4px #1114);
  background-color: white;
}

.web_location .map {
  height: 30%;
  min-height: 30%;
  /* width: 100%; */
  border-radius: 6px;
  margin: 0 8px;
  overflow: HIDDEN;

  box-sizing: border-box;
}

.web_location .choose_city {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.web_location .choose_city,
.web_location .choose_province {
  padding: 12px;
}

.web_location .change_province {
  cursor: pointer;
}

.web_location *::-webkit-scrollbar-thumb {
  background-color: #2196f3;
  /* width: 8px; */
  /* display: block !important; */
  border-radius: 10px;
}

.web_location *::-webkit-scrollbar-track {
  /* width: 8px; */
  background-color: var(--bg);
  /* display: block !important; */
  border-radius: 10px;
}

.web_location *::-webkit-scrollbar {
  width: 8px;
  display: block !important;
  border-radius: 10px;
}

.web_location .loader_cont {
  flex: 1;
  /* height: 400px; */
  padding: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
}
.web_location .regions * {
  overflow: visible !important;
}

.location_modal {
  padding: 0;
  overflow: hidden;
}

.location_modal .close {
  height: 44px;
  width: 44px;
  z-index: 10000;
}

.location_modal .close svg {
  height: 32px;
  width: 32px;
}

.map div {
  /* transition: all 0.3s var(--bc); */
  cursor: crosshair;
}
.map .map_marker img {
  bottom: 0;
  filter: drop-shadow(0 28px 12px #1116);
  height: 64px;
  left: 0;
  position: absolute;
  transform: translate(-50%);
  transform: rotateX(45deg) translate(-50%) perspective(10000px);
  width: 34px;
}
.selections .tile {
  border-radius: 6px;

  min-width: 320px;

  transition: all 0.2s var(--bc);
}

.tile .regions_container {
  overflow-y: scroll !important;
  flex: 1;
}
.tile .heading button {
  border: navajowhite;
  background: none;
  color: var(--blue);
  margin: -12px;
}
.tile .heading button svg {
  height: 28px;
  width: 28px;
  stroke: white;
  stroke-width: 0.4;
}
.web_location .tile.prov {
  overflow: hidden !important;
  flex: 1;
}
.gm-style iframe + div {
  border: none !important;
}
.map_marker img {
  animation: 0.4s drop var(--bc);
  transform-origin: bottom center;
  /* transition: none !important; */
}

@keyframes drop {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.web_location h3.heading {
  font-size: 16px;
  color: black;
  height: 40px;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.web_location .select_range {
  flex: unset;
  margin: 0 8px 8px;
  display: flex;
  padding: 12px !important;
  flex-direction: column-reverse;
  justify-content: center;
  gap: 0;
}
.web_location .regions {
  overflow: hidden !important;
}
.web_location .select_range > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  height: 24px;
  font-size: 14px !important;
}
.web_location .select_range .search_by_address {
  flex-direction: row-reverse;
  font-size: 14px;

  font-weight: 500;
}
.web_location .select_range P {
  min-width: fit-content;
}
.web_location .select_range > div H4 {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.web_location .current_loc h4 {
  min-width: fit-content;
}

.web_location .or {
  justify-content: center !important;
  color: #777 !important;
  width: 100%;
  margin: 0;
  height: 0px !important;
  font-weight: 600;
  align-items: center !;
}

.web_location .current_loc.f {
  padding-bottom: 6px;
}
.web_location .map button,
.gm-bundled-control {
  display: none !important;
}
.current_loc.select_range.tile {
  min-height: fit-content;
  box-sizing: border-box;
  gap: 4px;
  max-height: 80px;
}
@media (min-width: 800px) {
  .web_location .selections {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: calc(100% - 40px);
    box-sizing: border-box;
    padding: 16px;
  }
  .web_location .map {
    height: 40%;
    min-height: 260px;
  }
  .web_location .regions {
    overflow: hidden !important;
    flex-direction: row;
  }
  .selections .tile {
    border-radius: 6px;

    padding: 12px !important;
    min-width: 320px;
    min-height: -moz-fit-content;
    flex: 1;

    transition: all 0.2s var(--bc);
  }
  .web_location .recents {
    height: fit-content;

    max-height: unset;
    transform: scaleY(1);
    opacity: 1;
    transform-origin: top center;
    transition: all 0.2s var(--bc);
  }

  .tile .heading button svg {
    display: none;
  }
}
