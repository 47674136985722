.upload {
  gap: 50px;
  width: 100%;
  margin: 0 auto;
  grid-template-rows: auto auto;
  display: flex;

  flex-direction: column;
}

.upload .row {
  display: grid;
  /* grid-template-columns: 60% 40%; */
  grid-gap: 20px;
  padding: 0px !important;
}

.upload .file_up_cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 440px !important;
  box-shadow: inset 0px 0px 8px #1112;
  border-radius: 4px;
  width: 100%;
  transition: all 0.3s var(--bc);
}
.upload .file_up_cont p {
  font-size: 22px;
}
.upload .file_up_cont:hover {
  background: #2196f311;
}

.upload .info_cont {
  padding: 20px 0px 0px 10px;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 40%;
}

.upload .check_icon {
  margin-bottom: -8px;
  margin-right: 10px;
  color: var(--blue);
  font-size: 24px;
}

.upload .file_up_cont SVG {
  height: 120px !important;
  width: 120px !important;
  fill: var(--blue);
}

.upload .dragging {
  background-color: #f5f5f5;
}

.upload input[type="file"] {
  display: none;
}
.dragged {
  border: 1px solid var(--blue);
  opacity: 1 !important;
}

/* .upload .image_gallery {
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  padding: 10px;
  width: 100%;
} */

.upload .image_scroll {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-auto-rows: minmax(160px, 20vw);
  justify-content: space-between;
  gap: 10px;
  filter: drop-shadow(0px 0px 4px #1115);
}

.preview_image_modal {
  height: 70vh;
  width: 70vw;
}

.preview_image_modal img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.upload .image_item {
  min-width: 160px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* background: #2196f322; */
  position: relative;
  transition: all 0.3s var(--bc);
  /* border: 1px solid var(--blue); */
  box-sizing: border-box;
  background-color: white;
}

.upload .image_item:last-child {
  margin-right: 0;
}

.upload .image_item .image_blob {
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* box-shadow: 0px 0px 8px #ccc; */
  border-radius: 4px;
}

.upload .img_container {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.upload .add_photo_icon {
  height: 40px !important;
  width: 40px !important;
  fill: var(--blue);
}

.upload .image_options {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: end;
  padding: 4px;
  align-items: center;
  gap: 5px;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
}
.upload .carousel_delete_icon {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #1118;
  color: white;
  box-shadow: 0 0 8px #1118;
  transition: all 0.3s var(--bc);
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 100;
}
.upload .carousel_delete_icon svg {
  height: 20px;
  width: 20px;
}

.upload .preview_image_container {
  position: fixed;
  top: 0;
  left: 0;
  height: 80vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload .image_scroll .lds-ring {
  transform: scale(0.75);
}

.upload .preview_image_container img {
  height: 80vh;
  width: 80vw;
  min-height: 600px;
  min-width: 1000px;
}
.image_gallery::-webkit-scrollbar {
  display: none;
}

.image_gallery .cover:first-child:before {
  content: "Cover Image";
  z-index: 100;
  position: absolute;
  padding: 3px 10px;
  top: 0px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  left: 0px;
  background-color: var(--blue);
  color: white;
  font-weight: 500;
  font-size: 13px;
  box-shadow: 0px 0px 4px #1116;
}

@media (min-width: 800px) {
  .upload .image_scroll {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-auto-rows: minmax(160px, 220px);
    justify-content: space-between;
    gap: 16px;
  }
}
