/* Notification.css */
.notification-container {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  z-index: 99999;
  gap: 8px;
}

@keyframes notification_slide {
  0% {
    top: -100%;
  }
  100% {
    top: 00px;
  }
}

.notification-container .notification {
  padding: 12px;
  border-radius: 0px;
  box-shadow: 1px 1px 8px #1112, -1px -1px 8px #1112;
  cursor: pointer;
  width: -moz-fit-content;
  /* width: fit-content; */
  max-width: 100%;
  max-height: 100px;
  min-height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  justify-content: space-between;
  position: fixed;
  animation: 0.2s notification_slide var(--bc);
  transition: all 0.2s ease-in;
  gap: 8px;
  font-size: 14px;
  top: 0;
  left: 0;
  box-sizing: border-box;
}

.notification-container .notification.info {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: white;
}

.notification-container .notification.success {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: white;
}

.notification-container .notification.warning {
  border: 1px solid var(--yellow);
  background-color: var(--yellow);
  color: white;
}

.notification-container .notification.error {
  border: 1px solid var(--red);
  background-color: var(--red);
  color: white;
}

.notification-container .notification.hidden {
  transform: translateY(100%);
  opacity: 0;
}
.notification.removed {
  transform: translateX(100%);
  opacity: 0.6;
}
