._listings {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 6px;
  background: white;
  grid-auto-flow: dense;
  padding-bottom: 10vh;
  gap: calc(0.6vh + 0.6vw) !important;
  background-color: transparent !important;
}
._listing .empty_img {
  height: 100%;
  width: 100%;
}
._listing .overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 4;
  width: 100%;
  height: 100%;
  transition: all 0.1s var(--bc);
}

._listing .info {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 4px 8px 8px;

  background: linear-gradient(180deg, #fff9, #fffb, #fffd, #fff, #fff);
  display: FLEX;
  flex-direction: COLUMN;
  gap: 4px;
  justify-content: start;
  align-items: start;
  box-sizing: border-box;
  backdrop-filter: blur(8px);
  height: 40%;
  min-height: fit-content;
}
._listing.highlighted .info {
  height: calc(100%);
  left: 50%;
  width: 50%;
  background: var(--yellow);
  padding: 12px 6px;
  gap: 4px;
  padding-top: 32px;
  justify-content: space-between;
}
._listing .info p,
._listing .info h2,
._listing .info h3,
._listing .info h4 {
  margin: 0;
  line-height: 1;
}

._listing .pricing {
  width: 100%;
  overflow: hidden;
  order: 1;
  font-family: var(--font1);
  font-weight: 500;
  margin-top: 2px;
}

._listing h3.price {
  font-size: 21px;
  font-family: var(--font1);
  color: var(--blue);
  font-weight: 700;
}
._listing h4.price_hidden {
  font-size: 16px;
  font-family: var(--font2);
  color: var(--blue);
  font-weight: 500;
}

._listing h3.price span,
._listing .info .tax {
  font-size: 13px;
  font-weight: 500;
  font-family: var(--font2);
  color: black;
}
._listing .info .secondary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  order: 1;
  width: 100%;
  gap: 6px;
}
._listing .info p.location {
  font-size: 13px;
  flex: 1;
  text-overflow: ellipsis;
  white-space: NOWRAP;
  overflow: hidden;
  font-weight: 500;
}
._listing .info p.location svg {
  fill: var(--blue);
  height: 17px;
  margin-left: -6px;
  margin-right: -3px;
  vertical-align: text-bottom;
}
._listing .info .installments {
  font-size: 16px;
  font-weight: 600;
  color: var(--blue);
  font-family: var(--font1);
}
._listing .info .pricing {
  display: flex;
  align-items: baseline;
  gap: 2px;
  justify-content: start;
}

._listing h4.title {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font2);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  order: 1;
}

._listing.highlighted .pricing {
  order: 0;
  margin-bottom: 4px;
}
._listing.highlighted .badges {
  right: unset;
  left: calc(50% + 6px);
  top: 6px;
}
._listing.highlighted h3.price {
  font-size: 23px;
}
._listing.selected .overlay {
  background-color: #2196f333;
  pointer-events: none;
}
._listings ._listing {
  display: flex;
  padding: 0px;
  flex-direction: column;
  min-width: 160px;
  background: white;
  margin-bottom: 2px;
  overflow: hidden;
  border-radius: 12px;
  height: calc(6vh + 2vw + 120px);
  position: relative;
  background-size: contain;
  max-height: 240px;
  box-shadow: 0px 0px 12px #1115;
}
._listings ._listing.highlighted {
  background-color: var(--yellow);
  flex-direction: row;
  grid-column: span 2;
  gap: 6px;
  box-sizing: border-box;
  height: 160px;
  background-position: left center;
  width: 100%;
}
._listing p.description {
  margin: 0;
  font-size: 14px;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  height: calc(2 * 1.1rem);
  line-height: 1.1rem;
}

._listings ._listing.highlighted .image {
  width: calc(50% + 6px);
  min-width: calc(50% + 6px);

  min-height: 80%;
  max-width: calc(50% + 6px);
  max-height: 100%;
  height: 100%;
}

._listing .image .wishlist {
  height: 28px;
  width: 28px;
  position: absolute;
  top: 4px;
  left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  overflow: hidden;
  transition: all 0.1s var(--bc);
  border-radius: 50%;
  pointer-events: all !important;
  z-index: 10;
  filter: drop-shadow(2px 2px 6px #1114);
}

._listing.selected .wishlist {
  background-color: var(--blue);
}
._listing .image .wishlist svg {
  fill: transparent;
  stroke-width: 2;
  stroke: var(--red);
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

._listing .info,
._listing .info * {
  pointer-events: none;
}

._del svg {
  fill: var(--red) !important;
}

._listing .tax {
  font-size: 12px;
  font-family: var(--font1);
  font-weight: 500;
}

._listing .distance {
  position: absolute;
  background: var(--blue);
  padding: 2px 4px;
  font-size: 11px;
  bottom: calc(100% + 3px);
  color: white;
  border-radius: 4px;
  font-weight: 500;
  font-family: var(--font1);
  right: 3px;
}

.highlighted .status.active {
  bottom: 6px;
  left: calc(-100% + 4px);
}
._listing.selected .wishlist svg {
  fill: white !important;
  stroke: white !important;
}
._listing .image .wishlist.active svg {
  fill: var(--red);
}
._listing .image {
  height: 75%;
}
.badges {
  position: ABSOLUTE;
  top: 4px;
  right: 4px;
  font-size: 11px;
  font-weight: 500;
  font-family: VAR(--font1);
  display: flex;
  z-index: 2;
}
.badges span {
  padding: 3px 6px;
  border-radius: 10px;
  box-shadow: 0px 0px 4px #1116;
}
span.featured {
  background: var(--yellow);
}
._listing .image.empty {
  box-shadow: none;
}
._listing .image img {
  object-fit: cover;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}
._listing.highlighted .image img {
  height: 100%;
  max-height: 100%;
  min-height: 100%;
}

.highlighted .badges span {
  background: white;
}

._listings.loading {
  padding-bottom: 20px;
}

._listing.em {
  pointer-events: none;
  box-shadow: 0px 0px 8px #1112;
}
._listing.em .info {
  gap: 3px;
}
._listing.highlighted .distance {
  background: var(--blue);
  padding: 2px 4px;
  font-size: 11px;
  color: white;
  border-radius: 4px;
  font-weight: 500;
  font-family: var(--font1);
  position: absolute;
  right: calc(100% + 4px);
  z-index: 123;
  top: 6px;
  bottom: unset;
  text-overflow: ellipsis;
  left: unset;
  white-space: nowrap;
  box-shadow: 0px 0px 4px #1114;
}

._listing h4.title.empty {
  height: 19px;
}
._listing p.location.empty {
  height: 15px;
  margin-top: 3px;
}
._listing h2.empty {
  height: 25px;
  width: 60%;
}
@media (min-width: 640px) {
  ._listings {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
  }

  ._listings ._listing.highlighted {
    height: calc(6vh + 2vw + 120px);
    /* height: unset; */
  }
}
@media (min-width: 740px) {
  ._listings {
    padding: 8px 12px;
  }
  ._listing h4.title.empty {
    height: 21px;
  }
  ._listing p.location.empty {
    height: 17px;
    margin-top: 3px;
  }
  ._listing h2.empty {
    height: 32px;
    width: 60%;
  }
  ._listing p.description {
    margin: 0;
    font-size: 14px;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    height: calc(3 * 1.1rem);
    line-height: 1.1rem;
  }
  ._listing.highlighted .badges {
    top: 8px;
  }
  ._listing .distance {
    right: 5px;
    bottom: calc(100% + 5px);
  }
  ._listing .highlighted .distance {
    top: 8px;
    right: calc(100% + 8px);
  }
  ._listing .info {
    padding: 6px 12px 12px !important;
    gap: 4px;
  }
  ._listing .secondary .type {
    font-weight: 500;
    font-size: 15px;
    font-family: var(--font1);
    color: white;
    padding: 5px 12px;
    border-radius: 4px;
    background-color: var(--blue);
  }
  ._listing.highlighted .info {
    padding-top: 36px !important;
  }
  ._listing .info h4.title {
    font-size: 18px;
  }
  ._listing .info h3.price {
    font-size: 25px;
    margin-bottom: -4px;
  }
  ._listing .info p {
    font-size: 15px !important;
  }
  ._listing .info .secondary p.location svg {
    height: 20px;
    width: 20px;
    margin: 0px -4px;
    margin-right: 0px;
  }
  ._listing .badges {
    top: 6px;
    right: 6px;
  }
  ._listing.highlighted .badges {
    left: calc(50% + 8px);
  }
  ._listing .price_hidden {
    font-size: 18px !important;
  }
}
@media (min-width: 1000px) {
  ._listings {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    padding: 10px 20px;
  }
}

@media (min-width: 1200px) {
  ._listings {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding: 12px 24px;
    gap: 12px;
  }
}

._listing .status {
  position: absolute;
  top: -24px;
  left: 4px;
  font-size: 11px !important;
  font-weight: 500;
  padding: 3px 6px;
  background: white;
  width: fit-content !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-family: var(--font1);
  box-shadow: 0px 0px 4px #1114;
  height: fit-content;
  color: white;
}

._listing.highlighted .status {
  position: absolute;

  top: unset;
}

._listing .status.active {
  background-color: var(--blue);
}
._listing .status.paused {
  background-color: #555;
}
._listing .status.expired {
  background-color: black;
}
._ad_actions {
  position: fixed;
  z-index: 20;
  top: 0px;
  height: calc(4vh + 3vw);
  left: 0px;
  display: flex;
  width: 100%;
  background: white;
  box-shadow: 0px 0px 16px #1116;
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
  box-sizing: border-box;
  animation: actions_slide_in 0.2s var(--bc) forwards;
  box-sizing: border-box;
  max-height: 80px;
  min-height: 56px;
}
._ad_actions h2 {
  flex: 1;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 2px;
  font-size: 15px;
  font-family: var(--font1);
  font-weight: 500;
}

.ad_config .ad_info {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 6px;
  box-shadow: 1px 1px 8px #1112, -1px -1px 8px #1112;
  box-sizing: border-box;
  overflow: hidden;
  padding: 8px;
  gap: 6px;
  justify-content: start;
  max-width: 100%;
  margin-top: 8px;
}

.ad_config .ad_info > div {
  display: flex;
  flex-direction: column;
  gap: 3px;
  overflow: hidden;
  width: fit-content;
}

.ad_config button {
  border-radius: 6px !important;
}

.ad_config .ad_info p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

.ad_config .ad_info h4 {
  font-weight: 600;
  margin: 0;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.ad_config .ad_info .location {
  margin: 0;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ad_config .ad_info .location svg {
  height: 18px;
  width: 18px;
  vertical-align: text-bottom;
  margin-left: -2px;
}

.ad_config .ad_info img {
  height: 90px;
  width: 100px;
  min-width: 100px;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: 0px 0px 2px #1112;
}

._ad_actions button {
  height: 40px;
  width: 40px;
  background: none;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  filter: drop-shadow(0px 0px 8px #1111);
  position: relative;
  overflow: hidden;
  min-width: fit-content;
}
._ad_actions svg {
  min-height: 26px;
  min-width: 26px;
  height: calc(2vw + 1vh);
  width: calc(2vw + 1vh);
  max-height: 32px;
  max-width: 32px;
  fill: var(--blue);
}
._ad_actions .action span {
  font-size: 13px;
  display: none;
}
._listing .info * {
  pointer-events: none;
}
@keyframes actions_slide_in {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

div.options.actions {
  z-index: 10;
}
.ad_config {
  display: block;
  flex-direction: column;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 12px;
  width: 100vw;
}

.ad_config .recurring_control {
  width: 100%;
}
.ad_config::-webkit-scrollbar {
  width: 8px;
  display: block !important;
}

.ad_config::-webkit-scrollbar-track {
  background: var(--bg);
}

.ad_config::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 10px;
}

.ad_config .line.location svg {
  fill: var(--blue);
}

.ad_config .btns_cont {
  padding: 40px 60px 10px;
}

.ad_config .password {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  box-shadow: inset 0px 0px 6px #ccc;
  border-radius: 6px;
  padding: 12px;
  box-sizing: border-box;
  font-size: 14px;

  height: 42px;

  max-height: 42px;
  margin-top: 16px;
  flex: 1;
  box-sizing: border-box;
  transform: scaleY(0);
  transform-origin: top center;
  opacity: 0;
  animation: 0.3s open forwards var(--bc);
}
.ad_config .password svg {
  fill: var(--blue);
}

@keyframes open {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

.ad_config .password input {
  border: none;

  outline: none !important;
  font-size: 14px;
  flex: 1;
  min-width: unset !important;
}

.ad_config .password span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  transition: all 0.3s var(--bc);
  margin-right: -12px !important;
}

.ad_config .recurring_ad {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
}

.ad_config .status_rr p {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  text-align: right;
  display: flex;
  align-items: center;
  font-family: var(--font1);
  gap: 4px;
}
.ad_config .status_rr p.blue {
  color: var(--blue);
}
.ad_config p.red {
  color: var(--red);
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font1);
  margin-left: auto;
}
.ad_config h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font1);
  font-weight: 500;
  margin: 0;
  font-size: 16px;
  gap: 4px;
  height: 40px;
}

.ad_config {
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  padding: 12px;
  width: 100vw;

  padding-bottom: 60px;
  max-width: 1000px;
  margin: 0 auto;
}
.ad_config .controls {
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ad_config .controls svg {
  fill: var(--blue);
}

.ad_config button:disabled {
  background-color: #777;
}

.ad_config .change_status {
  display: flex;
  gap: 6px;
  max-width: 100%;
  overflow: hidden;
  flex-direction: column;
}
.ad_config .change_status button {
  height: 42px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  width: fit-content;
  padding: 16px;
  flex: 1;
  max-height: 42px;
  width: 100%;
  font-family: var(--font1);
}

.ad_config .configs {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 60px;
}
.ad_config .inactive {
  font-size: 18px;
  color: var(--red);
}

.ad_config .conf {
  padding: 20px;
  border-radius: 6px;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 4px #1114;
  margin: 16px 0 8px;
  flex: 1 1;
  display: flex;
  gap: 20px;
  background: #2196f322;
}
.ad_config .conf:first-child {
  background: #eee;
}

.ad_config .conf * {
  margin: 0;
}

.ad_config h2 p {
  margin-left: auto;
}
.ad_config .conf h5 {
  font-size: 18px;
  font-weight: 500;
  font-family: var(--font1);
}
.ad_config .conf .section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
}
.ad_config .conf .section li {
  padding: 6px 10px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 60px;
  box-sizing: border-box;
  font-size: 18px;
}
.ad_config .conf .section li span {
  color: var(--blue);
  font-size: 18px;
  font-family: var(--font1);
}
.ad_config .conf .section ul {
  width: 100%;
  padding: 0;
}
.ad_config .conf .section.col {
  flex-direction: column;
  align-items: start;
}

.ad_config .Premium {
  background: var(--yellow);
  border-radius: 6px;
  box-shadow: 0px 0px 8px #ccc;
  padding: 6px 20px;
  color: white;
}
.ad_config .Standard {
  background: var(--blue);
  border-radius: 6px;
  box-shadow: 0px 0px 8px #ccc;
  padding: 6px 20px;
  color: white;
}
.ad_config .Basic {
  background: #555;
  border-radius: 6px;
  box-shadow: 0px 0px 8px #ccc;
  padding: 6px 20px;
  color: white;
}

.ad_config .edit_config {
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  font-size: 15px;
  margin-left: auto;
}
.ad_config .edit_config svg {
  fill: white;
}

.ad_config .status_rr {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.ad_config .change_status * {
  margin: 0 !important;
}

.ad_config .config {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  gap: 6px;
  padding: 0;
}

.ad_config .config > div {
  flex: 1 1;
  min-width: 200px;
  border-radius: 6px;
  padding: 8px;
  box-shadow: 1px 1px 8px #1112, -1px -1px 8px #1112;
  border: 1px solid var(--blue);
}
.ad_config .config .config_header {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
}
.ad_config .config .details p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  height: 28px;
  margin: 0;
  font-weight: 500;
}
.ad_config .config .details p:last-child {
  border-bottom: none;
}
.ad_config .config .details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
}
.ad_config .num,
.ad_config .value {
  font-size: 15px;
  color: var(--blue);
}

.ad_config .total {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0;
  gap: 8px;
}
.ad_config .total .price {
  font-size: 22px;
  font-weight: 600;
  color: var(--blue);
}

@media (min-width: 520px) {
  .ad_config .change_status {
    flex-direction: row;
    gap: 10px;
  }
  .ad_config .change_status button {
    flex: 1;
    max-width: fit-content;
  }
}

@media (min-width: 720px) {
  ._ad_actions .action span {
    display: inline;
    font-size: 14px;
    color: #555;
    font-weight: 500;
  }
  ._ad_actions .action {
    border-radius: 0px;
    padding: 12px;
    gap: 2px;
  }

  .ad_config .config {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 8px;
    padding: 0;
    flex-direction: row;
  }
  .ad_config .recurring_ad {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: row;
    gap: 20px;
  }
}

.ad_config::-webkit-scrollbar,
.ad_config * ::-webkit-scrollbar {
  display: none !important;
}
@media (min-width: 1000px) {
  ._ad_actions .action span {
    display: inline;
    font-size: 15px;
  }
}
@media (min-width: 1600px) {
  ._listings {
    padding: 0 10% 100px;
  }
}

._listing .options {
  position: absolute;
  bottom: 0px;
  z-index: 99999;
  display: flex;
  right: 0px;
  padding: 12px 10px;
  width: 100%;
  justify-content: start;
  align-items: start;
  gap: 6px;
  flex-wrap: wrap;
  filter: drop-shadow(0px 0px 12px #1116);
  box-sizing: border-box;
  animation: pop_out 0.1s var(--bc);
  transition: all 0.1s var(--bc);
  transform: translateX(0);
  backdrop-filter: blur(10px);
}
._listing p.type {
  font-weight: 500;
  font-size: 13px;
  font-family: var(--font1);
  color: white;
  padding: 3px 6px;
  border-radius: 4px;
  background-color: var(--blue);
}

@keyframes pop_out {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
._listing .options button {
  border: none;
  color: black;
  display: flex;
  align-items: center;
  height: 36px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
  justify-content: start;
  border-radius: 20px;
  width: -moz-fit-content;
  width: fit-content;
  background: white;
  gap: 2px;
  font-size: 13px;
  font-family: var(--font1);
  font-weight: 500;
  overflow: hidden;
  flex: 1 1;
  position: relative;
}

._listing .wishlist.opt svg {
  fill: var(--blue) !important;
  stroke: none;
  height: 28px;
  width: 28px;
  stroke-width: 0;
}
._listing .options svg {
  height: 18px;
  fill: var(--blue);
  width: 18px;
}
._listing .overlay.white {
  background-color: #1112;
}
/* @media (min-width: 1000px) {
  ._ad_actions .action span {
    display: inline;
    font-size: 15px;
  }
  ._listing.highlighted .status {
    position: absolute;
    bottom: 12px;
    top: unset;
    left: 12px;
  }
}
@media (min-width: 1100px) {
  ._listings {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 6px;
  }
}

@media (min-width: 1600px) {
  ._listings {
    padding: 0 10% 100px;
  }
} */
