.wallet > h2,
._wallet_add_money > h2 {
  margin: 0;
  display: flex;
  font-weight: 500;

  align-items: center;
  gap: 4px;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.wallet > h2 svg,
._wallet_add_money > h2 svg {
  fill: var(--blue);
  height: 24px;
  width: 24px;
}
.wallet {
  border-radius: 6px;
  box-shadow: 0px 0px 8px #1114;
  background: white;
  padding: 16px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.wallet .wallet_main {
  display: flex;
  gap: 8px;
  align-items: baseline;
  justify-content: space-between;
}

.wallet .wallet_main h1 {
  margin: 0;
  font-weight: 600;
  font-family: var(--font1);
  font-size: 32px;
  overflow: hidden;
  flex: 1;
  text-overflow: ellipsis;
  color: var(--blue);
}

.wallet .wallet_main .add_money {
  font-size: 15px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  box-shadow: 0px 0px 4px #1114;
  color: white;
  border-radius: 6px;
  font-weight: 500;
  grid-area: 2px;
}
.wallet .wallet_main .add_money svg {
  height: 22px;
  width: 22px;
}

._wallet_add_money {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 20px 20px 80px;
  box-sizing: border-box;
  max-width: 540px;
  margin: auto;
}
._wallet_add_money .suggested_amounts {
  display: flex;
  gap: 8px;
  height: 50px;
  margin-top: -22px;
  align-items: center;
  justify-content: space-between;
}
._wallet_add_money .suggested_amounts p {
  margin: 0;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  color: white;
  border-radius: 8px;

  flex: 1;
  box-shadow: 0px 0px 8px #1112;
  cursor: pointer;
  transition: all 0.2s var(--bc);
  background-color: #2196f3;
  /* border: 1px solid var(--blue); */
  flex: 1;
}

._wallet_add_money .add_money_inp {
  height: 60px;
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 32px;
  color: var(--blue);
  background-color: #2196f320;
  font-weight: 600;
  padding: 8px 20px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: inset 0px 0px 4px #1112;
}
._wallet_add_money .add_money_inp input {
  font-size: 30px;
  border: none;
  outline: none !important;
  background: transparent;
  color: var(--blue);
  font-family: var(--font1);
  font-weight: 600;
}

._wallet_add_money .actions {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: end;
}

._wallet_add_money .actions button {
  height: 48px;
  border: none;
  background: var(--blue);
  font-size: 16px;
  border-radius: 6px;
  font-weight: 500;
  font-family: var(--font1);
  color: white;
  box-shadow: 0px 0px 12px #1114;
  cursor: pointer;
  transition: all 0.3s var(--bc);
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 0px;
  justify-content: center;
  flex: 1 1;
  box-sizing: border-box;
}
._wallet_add_money .actions button svg {
  height: 24px;
  width: 24px;
}
._wallet_add_money .actions button.cancel {
  border: 1px solid var(--red-dark);
  color: white;
  background-color: var(--red-dark);
}

._wallet_add_money .actions button:hover {
  background-color: var(--blue-dark);
}
._wallet_add_money .actions button.cancel:hover {
  background-color: var(--red);
  color: white;
}

.wallet ._info {
  position: relative;

  margin-left: auto;
}
