.custom_price_input {
  width: 100%;

  justify-content: center;
  box-sizing: border-box;
  gap: 5px;
  position: relative;
  border: none;
  flex: 1;
  outline: none !important;
  font-size: 20px;
  filter: drop-shadow(1px 1px 4px #1113);
  box-sizing: border-box;
  padding-left: 12px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: white;
  border: 1px solid var(--blue);
  min-width: 100%;
  overflow: hidden;
}
.custom_price_input .symbol {
  font-weight: 600;
  color: var(--blue);
  font-size: 15px;
  font-family: var(--font1);
}

.custom_price_input .price_input_field {
  width: 100%;
  display: flex;
  justify-content: center;

  box-sizing: border-box;
  align-items: start;
  gap: 5px;
  position: relative;
  border: none;
  flex: 1;
  outline: none !important;
  font-size: 22px;
  background-color: transparent;
  color: var(--blue);
  font-weight: 600;
  font-family: var(--font1);
  background: white;

  filter: none;
}

.option_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  filter: drop-shadow(0px 0px 4px #1114);
  width: 100%;
  flex-wrap: wrap;
}

.price_option {
  flex: 1 1;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  height: 38px;
  border-radius: 5px;
  background: white;
  transition: all 0.2s var(--bc);
  font-family: var(--font1);
  border: 1px solid var(--blue);
  padding: 0 12px;
}

.price_option.option_selected {
  background: var(--blue);
  color: var(--fg);

  opacity: 1;
}

.post_ad .field_container ._price_inp p {
  color: #555 !important;
  font-size: 16px;
  min-width: 40%;
  font-weight: 500;
}

.custom_price_input input {
  font-family: var(--font1);
  padding: 16px 12px;
  display: flex;
  align-items: center;
}
.custom_price_input input::placeholder {
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}
