._home {
  height: 100vh;
  overflow-y: scroll;
}

._home .header {
  height: 400px;
  position: relative;
  /* border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px; */
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 160%;
  background-image: url(../../assets/images/img.png);
  padding-top: 0px;
  padding-bottom: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  transition: all 0.2s var(--bc);
}
._home ._header {
  background: transparent;
  box-shadow: none;
}

._home .header .header-mask {
  background: linear-gradient(1turn, var(--blue-dark), #082d66);
  height: 100%;
  left: 0;
  opacity: 97%;
  position: absolute;
  top: 0;
  width: 100%;
}

._home .header .search,
._home .header .category-n-location {
  width: calc(100% - 24px);
  margin: 0 12px;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  max-width: 800px;
}

._home .header .search > div,
._home .header .category-n-location > div {
  background: white;
  z-index: 2;
  height: 100%;
  border-radius: 26px;
  box-shadow: 0px 0px 8px #1116;
  padding: 8px 10px;
  box-sizing: border-box;
}
._home .header .category-n-location > div {
  /* flex: 1; */
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  width: 160px;
}
._home .header .category-n-location > div * {
  pointer-events: none;
  /* position: relative; */
}
._home .header .category-n-location > div:first-child {
  min-width: 55%;
  flex: 1;
  gap: 4px;
}
._home .header .category-n-location > div:last-child {
  max-width: 300px !important;
  flex: 1;
}
._home .header .category-n-location > div:first-child img {
  width: 30px;
  object-fit: fill;
  margin-left: 4px;
}
._home .header svg {
  height: 24px;
  width: 24px;
  min-height: 26px;
  min-width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--blue);
}

._home .header .search > div input {
  height: 100%;
  flex: 1;
  background: transparent;
  box-sizing: border-box;
  outline: none;
  border: none;
  font-family: var(--font2);
  font-size: 15px;
  font-weight: 500;
  color: black;
  padding: 4px;
}
._home ._listings {
  background-color: transparent;
  animation: fade_in 0.2s var(--bc);
  will-change: transform;
  background-color: var(--bg);
}
._home .header .category-n-location p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@keyframes fade_in {
  0% {
    /* opacity: 0.8; */
    transform: translateY(100px);
  }
  100% {
    /* opacity: 1; */
    transform: translateY(0px);
  }
}

@keyframes tab {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
._home .results {
  margin-top: -90px;
}
._home .header .search .search-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
._home .header .search .filters-btn {
  height: 100%;
  width: 46px;
  display: flex;
  align-items: center;
  background: #2196f3;
  justify-content: center;
  position: relative;
  overflow: hidden;
  color: white;
}
._home .header .search .filters-btn svg {
  fill: white;
}

._home .header > h1 {
  margin: 20px 16px 20px;

  font-weight: 700;
  z-index: 2;
  text-align: center;
  filter: drop-shadow(0px 0px 8px #1116);
  color: white;
  font-family: var(--font1);
  margin-bottom: 10px;
  display: none;
}

._home ._listings {
  padding-bottom: 0;
}

._home .results {
  padding-bottom: 10vh;
}

._home .header .desc {
  color: white;
  z-index: 100;
  text-align: center;
  font-size: 16px;
  margin: 24px 12px 12px;
  max-width: 600px;
  filter: drop-shadow(0px 0px 8px #1116);
}
._home .header .tabs {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
  box-sizing: border-box;
  overflow-x: scroll;
  transition: all 0.2s var(--bc);
  margin: 0 auto;
  max-width: 800px;
  width: calc(100% - 24px);
}
._home .header .tabs::-webkit-scrollbar {
  display: none;
}

._home .header .search_tab {
  align-items: center;
  animation: 0.2s tab var(--bc) forwards;
  background: #fff;
  border: 2px solid var(--blue);
  border-radius: 26px;
  box-shadow: 0 0 8px #1116;
  box-sizing: border-box;
  color: black;
  display: flex;
  font-size: 14px;
  height: 36px;
  justify-content: center;
  min-height: 36px;
  min-width: fit-content;
  padding: 12px;
  transition: all 0.2s var(--bc);
  z-index: 2;
}

._home .header .search_tab p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  max-width: 45vw;
}
._home .header .search_tab.active {
  background: var(--blue);
  color: white;
  font-weight: 500;
}
._home .header .search_tab.add_tab {
  font-size: 36px;
  font-weight: 500;
  max-width: 36px;
  min-width: 36px;
}
._home .header .search_tab svg {
  height: 22px !important;
  width: 22px !important;
  max-height: 22px !important;
  min-width: 22px !important;
  min-height: 22px !important;
  max-width: 22px !important;
}
._home .header .search_tab button {
  border: none;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  margin-right: -6px;
  background: none;
}
._home .search_tab svg {
  height: 18px !important;
  width: 18px !important;
  transition: all 0.2s var(--bc);
}
._home .search_tab.active svg {
  fill: white;
}
._filters {
  padding: 0px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  /* gap: 6px; */
}
._filters .filter {
  padding: 12px 24px;
}
._filters .field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0 12px;
  gap: 20px;
}
._filters .field h2 {
  font-size: 17px;
  font-weight: 600;
  margin: 0;
  font-family: var(--font1);
}

._filters .filter .sort {
  background: white;
  padding: 12px;
  box-sizing: border-box;
  height: 52px;
  display: FLEX;
  align-items: center;
  border: 1px solid var(--blue);
  border-radius: 5px;
  filter: drop-shadow(0px 0px 4px #1115);
  font-family: var(--font1);
  justify-content: space-between;
  gap: 8px;
}

._filters .filter .sort h4 {
  font-weight: 600;
  font-size: 14px;
}
._filters .filter .sort p {
  flex: 1;
  font-weight: 500;
}
._filters .field .input_container {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 6px 12px;
  box-sizing: border-box;
  background-color: var(--blue);
  color: white;
  width: fit-content;
  max-width: 120px;
  font-family: var(--font1);
  font-weight: 600;
  font-size: 16px;
}

._filters .field input {
  background-color: var(--blue);
  padding: 0;
  color: white;
  font-family: var(--font1);
  height: fit-content;
  font-weight: 600;
  font-size: 16px;
}
._filters .filter .slider input {
  width: 100%;
}
._filters hr {
  border: 1px solid #eee;
  width: 100%;
  border-radius: 10px;
  display: none;
}

._filters .heading {
  display: flex;
  font-size: 24px;
  gap: 8px;
  justify-content: start;
  align-items: center;
  font-weight: 600;
}
._filters .heading svg {
  height: 32px;
  width: 32px;
  fill: var(--blue);
}

._filters .filter {
  /* height: 90px; */
}

input[type="range"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  overflow: hidden;
  border-radius: 16px;
  filter: drop-shadow(0px 0px 4px #1115);
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
  height: 16px;
  /* background: #eee; */
  border-radius: 16px;
  box-sizing: border-box;
  border: 1px solid var(--blue);
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
  height: 16px;
  /* background: #eee; */
  border-radius: 16px;
  box-sizing: border-box;
  border: 1px solid var(--blue);
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;

  height: 14px;
  width: 14px;
  background-color: white;
  border-radius: 50%;
  border: 3px solid var(--blue);
  box-sizing: border-box;
  position: relative;

  box-shadow: -100vw 0 0 calc(100vw - 5px) var(--blue);
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  appearance: none;
  height: 14px;
  width: 14px;
  background-color: white;
  border-radius: 50%;
  border: 3px solid var(--blue);
  box-sizing: border-box;
  position: relative;

  box-shadow: -407px 0 0 400px var(--blue);
}
._filters input[type="date"] {
  height: 50px;
  border: 1px solid #fff;
}

._filters .selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid var(--blue);
  box-shadow: 0px 0px 8px #ccc;
}
/* ._filters .term:hover {
  background-color: var(--blue);
  color: #fff;
} */
._filters .term.active {
  background-color: var(--blue);
  color: #fff;
}

._filters:-webkit-scrollbar {
  width: 8px;
  display: block !important;
  transition: all 0.3 var(--bc);
  display: block;
}

._filters:-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 10px;
  min-width: 10px;
  transition: all 0.3 var(--bc);
}

._filters:-webkit-scrollbar-thumb {
  background-color: var(--blue);

  border-radius: 10px;
  transition: all 0.3 var(--bc);
  min-width: 6px;
}

._filters .filter ._dropdown {
  height: 20px;
  flex: 1;
}

._filters .apply button {
  height: 100%;
  flex: 1;
  border: 1px solid var(--blue);
  border-radius: 4px;
  box-shadow: 0px 0px 8px #ccc;
  font-size: 20px;

  font-family: var(--font2);
  cursor: pointer;
  transition: all 0.3s var(--bc);
}
._filters .apply button:hover {
  background-color: var(--blue-dark);
  color: white;
}

._filters div.apply {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  height: 48px;
  margin: 40px 0 0;
}

._filters button.apply {
  background-color: var(--blue);
  color: white;
}

._filters button.reset {
  background-color: white;
  color: var(--blue);
}

._filters .terms {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  flex-wrap: wrap;

  padding-right: 10px;
  filter: drop-shadow(0px 0px 4px #1115);
}

._filters .term {
  padding: 10px 24px;
  background: #fff;
  border: 1px solid var(--blue);
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
  flex: 1 1;
  transition: all 0.2s var(--bc);
  cursor: pointer;
  font-family: var(--font1);
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s var(--bc);
  white-space: nowrap;
  max-width: 100%;
}

.mobile_modal.filters .content {
  flex: 1 1;
  max-height: 600px;
  overflow-y: scroll;
}
.mobile_modal.filters *::-webkit-scrollbar {
  display: none !important;
}
@media (min-width: 720px) {
  ._home .header > .desc {
    font-size: 20px;
    max-width: 800px;
    margin: -12px 32px 16px;
  }
  ._home .header > h1 {
    display: flex;
    margin-top: 40px;
    max-width: 800px;
    font-size: 42px;
  }
  ._home .header .search,
  ._home .header .category-n-location {
    width: calc(100% - 64px);
  }
  ._home .header {
    height: 500px;
    gap: 12px;
  }

  ._filters .terms {
    gap: 12px !important;
  }
}

@media (min-width: 1120px) {
  ._home .header > h1 {
    font-size: 48px;
    margin: 10px;
    max-width: 1000px;
  }
  ._home .header > .desc {
    max-width: 1000px;
    font-size: 24px;
  }
  ._home .header .search,
  ._home .header .category-n-location {
    max-width: 1000px;
    height: 56px;
  }
  ._home .header .search > div,
  ._home .header .category-n-location > div {
    border-radius: 30px;
    min-width: 56px;
    padding: 12px 16px;
    font-size: 18px;
    gap: 6px !important;
  }
  ._home .header .search > div input {
    font-size: 18px !important;
  }
  ._home .header {
    height: 560px;
  }

  ._filters {
    gap: 20px;
  }
  .mobile_modal.filters {
    max-width: 620px;
    width: 60%;
    padding: 20px 12px;
  }
}
