#portal {
  display: flex;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 99999;
}

.mobile_modal {
  padding: 20px 0px 40px;
  border-radius: 24px;
  background: white;
  box-shadow: 0px 0px 24px #1116;
  min-width: 200px;
  animation: 0.3s mobile_modal_open var(--bc) forwards;
  z-index: 999;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 100vw;
  height: -moz-fit-content;
  height: fit-content;
  box-sizing: content-box;
  position: fixed;
  margin-bottom: -20px;
  transform: translateY(0%);
  opacity: 1;
  will-change: transform;
  max-width: 600px;
}
.mobile_modal.payment {
  max-width: unset;
  height: 100vh;
  border-radius: 0px;
  top: 0;
  padding: 0;
}
.mobile_modal.payment .content {
  flex: 1 1;
  max-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  top: 60px;
  position: absolute;
  width: 100vw;
  overflow-y: scroll;
}

.mobile_modal.payment .payment_form {
  width: 100%;
  padding: 0px 16px 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: start;
  align-items: start;
  height: 100vh;
  flex-direction: column;
  overflow-y: scroll;
  position: absolute;
  box-sizing: border-box;
  width: 100vw;
  padding-bottom: 60px;
}

.mobile_modal.payment .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  padding: 0 16px;
  padding-top: 0px;
  font-family: var(--font1);
  z-index: 99 !important;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  height: 60px;
  width: 100vw;
  box-sizing: border-box;
}
.mobile_modal.ad,
.mobile_modal.gallery {
  border-radius: 0;
  height: 100%;
  margin: 0;
  max-width: 100vw;
  overflow-y: auto;
  padding: 0;
  width: 100vw;
  position: fixed;
  top: 0;
}
.post_ad_handler {
  max-width: unset;
}
.mobile_modal.ad .header,
.mobile_modal.gallery .header {
  flex-direction: row-reverse;
  justify-content: start;
  padding: 0 20px;
  height: 48px;
  align-items: center;
  min-height: 48px;
  max-height: 48px;
  box-sizing: border-box;
  margin-bottom: -12px;
  gap: 12px;
  font-size: 16px;
  z-index: 4;
}

.mobile_modal.ad .header .close,
.mobile_modal.gallery .header .close {
  margin: 0 -12px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 40px;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  color: var(--blue) !important;
  background-color: transparent;
  color: var(--blue);
  top: unset;
  right: unset;
  box-shadow: unset;
}

.mobile_modal.ad .header .close svg,
.mobile_modal.gallery .header .close svg {
  fill: var(--blue);
}

.mobile_modal.gallery .header {
  filter: drop-shadow(0px 0px 16px #1116);
}
.mobile_modal.location {
  border-radius: 0;
  box-sizing: border-box;
  height: 100vh;
  margin-bottom: 0;
  max-width: none;
  padding: 12px 0 0;
  position: fixed;
  top: 0;
}
.mobile_modal.location .header {
  padding-bottom: 8px;
  padding-top: 4px;
}

.modal_overlay {
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: end !important;
  background-color: rgba(0, 0, 0, 0.45);
}
.mobile_modal.closing {
  animation: 0.25s reverse_modal_open ease-in forwards;
}

/* .modal_overlay {
  align-items: end !important;
  bottom: 0 !important;
  top: unset !important;
} */

.mobile_modal .close {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  border-radius: 50%;
  border: none;

  top: 12px;
  right: 12px;
}

.mobile_modal .close svg {
  height: 25px;
  width: 25px;
}
.mobile_modal.image {
  padding: 60px 0px 80px;
  max-width: unset;
}
.mobile_modal.image .content {
  /* padding: 0 20px; */
}
.mobile_modal.image > .header {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
}

.mobile_modal .content {
  /* height: 100%; */
  flex: 1;
  max-height: 100vh;
  /* width: 100vw; */
}

.mobile_modal .content > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  max-height: 80vh;
  /* padding: 20px 0; */
  /* border-radius: 12px; */
}

.mobile_modal .content::-webkit-scrollbar {
  width: 6px;
  display: block !important;
}

.mobile_modal .content::-webkit-scrollbar-track {
  background: var(--bg);
}

.mobile_modal .content::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 3px;
  /* border: 3px solid var(--blue); */
}

.mobile_modal > .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  padding: 16px;
  padding-top: 0px;
  font-family: var(--font1);
  z-index: 99 !important;
  /* margin-bottom: 10px; */
}
@keyframes mobile_modal_open {
  0% {
    transform: translateY(60%);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes reverse_modal_open {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(100%);
    /* opacity: 0; */
  }
}
.mobile_modal .header span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.mobile_modal .header span svg {
  fill: var(--blue);
  height: 20px;
  width: 20px;
}
@media (min-width: 1000px) {
  .mobile_modal.payment {
    padding: 40px 6% 20px;
  }
}
.mobile_modal.payment .content::-webkit-scrollbar {
  display: none !important;
}
