.input_container {
  display: flex;
  flex: 1;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  width: 100%;
  border: 1px solid var(--blue);
  border-radius: 4px;
}

.input_container input {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: 0px 20px;
  box-sizing: border-box;
  align-items: center;
  gap: 5px;
  position: relative;
  border: none;
  flex: 1;
  outline: none !important;
  font-size: 15px;
  filter: drop-shadow(0px 0px 2px #1111);
  font-family: var(--font2);
}

.input_container .eye_icon {
  position: absolute;
  right: 10px;
  height: 28px;
  width: 28px;
  fill: var(--blue);
  cursor: pointer;
}
