._dropdown {
  background: var(--fg);
  border-radius: 4px;
  color: black;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  height: 18px;
  /* justify-content: center; */
  padding: 14px;
  align-items: center;
  filter: drop-shadow(1px 1px 4px #1114);
  z-index: 10;
  position: relative;
  border: 1px solid var(--blue);

  /* width: 100%; */
}

._dropdown.active {
  z-index: 99;
}
._dropdown .option {
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}

._dropdown .option svg {
  min-height: 28px;
  width: 28px;
  object-fit: contain;
}

._dropdown .selected {
  align-items: center;
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  cursor: pointer;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
}
._dropdown .arrow svg {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  fill: var(--blue);
}
._dropdown .options {
  background: var(--fg);
  padding: 0px 0px;
  width: calc(100% + 32px);
  border-radius: 4px;
  transform: scaleY(0);
  transition: all 0.3s var(--bc);
  opacity: 0;
  transform-origin: top center;
  min-height: 200px;
  overflow: scroll;
  position: relative;
  top: 22px;
  z-index: 10;
  /* border: 1px solid var(--blue); */
}
._dropdown .options.active {
  transform: scaleY(1) !important;
  opacity: 1 !important;
}
._dropdown .option {
  display: none;
  /* padding: 0 16px; */
  justify-content: start;
}
._dropdown.active .option {
  display: flex;
}

._dropdown .options .option {
  padding: 8px 0px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 12px 16px;
  justify-content: start;
  font-size: 18px;
  margin: 0;
  align-items: center;
}

._dropdown .options .option:hover {
  background-color: #d3d3d399;
}
._dropdown .options hr {
  display: none;
}

._dropdown .selected * {
  pointer-events: none !important;
}

._dropdown .selected > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 20px;
}

._dropdown .selected > div span {
  font-size: 13px;
  font-weight: 600;
  font-size: 13px;
  font-weight: 600;
  color: #333;
  position: absolute;
  top: -4px;
  left: 0;
}
