.info_page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(145deg, var(--blue) 49.9%, #fff 50%);
  padding-bottom: 100px;
  box-sizing: border-box;
}

.info_page .main {
  height: fit-content;
  width: 800px;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 100px;
  padding-top: 60px;
  box-sizing: border-box;
  gap: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 1px 1px 8px #1112, -1px -1px 8px #1112;
  text-align: center;
  width: calc(100% - 24px);
  padding: 100px 20px;
  max-width: 600px;
  margin: auto;
}

.info_page h1 {
  font-weight: 500;
  margin: 0;
  font-size: 20px;
}
.info_page .main img {
  height: 12vw;
  max-height: 60px;
}
