._n_settings {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: calc(100% - 16px);
  margin: auto;
  max-width: 600px;
  border-radius: 8px;
  box-sizing: border-box;
}
._n_settings h2 {
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  /* margin-bottom: 10px; */
}
._n_settings h2 svg {
  height: 32px;
  width: 32px;
  fill: var(--blue);
}

._n_settings .config {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
._n_settings .config li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  color: #333;
  padding: 16px;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 0px 8px #1114;
}
