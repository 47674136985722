.Share {
  padding:24px 24px 52px;
  display: flex;
  gap: 12px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  margin: auto;
  justify-content: space-between;
}

.Share a {
  border: none;
  height: 56px;
  width: 56px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s var(--bc);
  will-change: transform;
}

.Share a:hover {
  transform: scale(1.1);
}
.Share svg {
  height: 40px;
  color: white;
  width: 40px;
}

.Share .fb {
  background-color: #1877f2;
}

.Share .wa {
  background-color: #128c7e;
}

.Share .tw {
  background-color: #14171a;
}

.Share .cb {
  background-color: #555;
}

.Share .tg {
  background-color: #0088cc;
}

.Share .text {
  position: absolute;
  font-size: 12px;
  color: #555;
  font-weight: 500;
  top: 105%;
  font-family: var(--font2);
  text-align: center;
  will-change: transform;
  margin: 0;
  opacity: 0;
  transition: all 0.3s var(--bc);
  line-height: 1.1;
}

.Share a:hover .text {
  opacity: 100%;
}
