.faq_page .accordion h1 {
  font-size: 15px;
  text-align: left;
  width: 100%;
  margin: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faq_page h1.main_header {
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  margin: 0;
  width: 100%;
}

.faq_page .accordion h1 svg {
  height: 24px;
  width: 24px;
  fill: var(--blue);
}
.faq_page .FAQ h1 span,
.faq_page .FAQ > div,
.faq_page .FAQ,
.faq_page iframe {
  transition: all 0.2s var(--bc);
}
.faq_page .FAQ.active h1 span {
  transform: rotate(180deg);
}

.faq_page .FAQ > div {
  width: 100%;
  /* margin-top: 20px; */
}
.faq_page iframe {
  width: 100%;
  display: flex;
  object-fit: fill;
  justify-content: start;
  height: 0px;
  align-items: start;
  opacity: 0;
}
.faq_page .FAQ.active iframe {
  margin-top: 12px;
  opacity: 1;
  height: 50vw;
}

.faq_page .content {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* width: 80%; */
  margin: 140px 10% 20px;
  padding: 40px;
  background: white;
  box-shadow: 0px 0px 8px #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.faq_page .FAQ {
  width: 100%;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0px 0px 12px #1114;
  background: white;
  cursor: pointer;
  box-sizing: border-box;
}

.faq_page .accordion {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 12px;
  box-sizing: border-box;
}

.faq_page .desc_row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.vp-center {
  display: flex;
  justify-content: start;
  align-items: start;
}
