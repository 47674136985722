.mobile_checkbox {
  height: 20px;
  width: 20px;
  box-shadow: 0px 0px 4px #1114;
  border: 1px solid var(--blue);
  border-radius: 3px;
  font-size: 1.5rem;
  min-height: 20px;
  min-width: 20px;
}

.mobile_checkbox .checked {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue);
  color: #fff;
}
.mobile_checkbox .checked svg {
  fill: white !important;
}

.mobile_checkbox svg {
  font-size: 1.5rem;
}
