.help_container {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: flex-start; */
  /* justify-content: flex-start; */
}

.help_container .content {
  width: 100%;
}

.help_container .help_input {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.help_doc_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  margin-bottom: 60px;
  background: white;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  border-radius: 4px;
  gap: 0px;
  width: 100%;
}
.help_doc_container table {
  margin-left: 0;
}
.help_doc_container td,
.help_doc_container td * {
  width: 300px !important;
  text-align: center !important;
}

.help_container .help_content {
  width: 80%;
  margin: 0 auto;
  margin-top: 150px;
}

.help_container .pass_cont {
  display: flex;
  flex-direction: column;
  padding: 0 12px 50px;
  gap: 8px;
}

.help_container .pass_cont .heading {
  /* font-size: 24px; */
  font-weight: 600;
  color: #444;
  text-align: center;
}

.help_container .input_container {
  display: flex;
  flex: 1 1;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  width: 100%;
  border: 1px solid var(--blue);
  border-radius: 6px;
  background: #2196f315;
  box-shadow: 0px 0px 8px #1114;
}

/* media query */
@media (max-width: 500px) {
  .help_container .pass_cont .heading {
    font-size: 16px;
  }
}

.help_container .pass_cont .cards_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin: 0px auto;
}

.help_container .card {
  color: var(--blue);
  text-decoration: none;
  flex: 1;
  min-width: fit-content;
}

.help_doc_container * {
  margin-top: 0px;
  margin-bottom: 12px;
  padding: 0 !important;
  font-family: var(--font2) !important;
}

.help_doc_container p span,
.help_doc_container li span {
  font-size: 15px !important;
  font-family: var(--font2) !important;
}
.help_doc_container h1,
.help_doc_container h1 span,
.c15 .c0,
.c15 {
  font-size: 32px !important;
  font-weight: 500;
  font-family: var(--font1) !important;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  display: block;
}

.help_doc_container h1,
.help_doc_container h2,
.help_doc_container h3,
.help_doc_container h4,
.help_doc_container h1 span,
.help_doc_container h2 span,
.help_doc_container h3 span,
.help_doc_container h4 span {
  font-weight: 500 !important;
  font-family: var(--font1) !important;
}
.help_doc_container h2,
.help_doc_container h2 span {
  font-size: 24px !important;
}

.help_container .pass_cont .cards_row .card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 0px 12px #1114;
  border-radius: 6px;
  cursor: pointer;
  gap: 12px;
  transition: all 0.2s var(--bc);
}

.help_container .pass_cont .cards_row .card:hover {
  box-shadow: 0px 0px 8px #ccc;
  border-radius: 4px;
  background: #2196f322;
  /* //animate */
  /* transform: scale(1.05); */
}

.help_container .pass_cont .cards_row .card .card_label {
  font-size: 17px;
  font-weight: 500;
  text-align: center;
}

.help_container .pass_cont .cards_row .card .card_description {
  font-size: 15px;
  font-weight: 400;
  color: #333;
  text-align: center;
}

.dangerous_html_cont {
  background-color: #fff;
  filter: drop-shadow(1px 1px 8px #ccc);
  padding: 30px 10%;
}

/* .dangerous_html_cont responsive */
.help_doc_container .our-mission p span {
  font-size: 16px !important;
}
@media (min-width: 768px) {
  .help_doc_container {
    padding: 50px;
  }
}
