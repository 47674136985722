.contact_us_card {
  padding: 0 6px 40px;
  box-sizing: border-box;
}

.contact-us-form {
  border-radius: 8px;
  color: #555;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contact_us_card .small_cont {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 8px #1114;
}

.contact-us-form .fill {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  min-width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 8px #1114;
  margin-bottom: 8px;
  flex-direction: column;
}

.contact_us_card .input_container {
  display: flex;
  flex: 1 1;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  width: 100%;
  border: 1px solid var(--blue);
  border-radius: 4px;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
}

.contact_us_card .input_container input {
  width: 100%;
  height: 40px;
  min-height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: 0px 12px;
  box-sizing: border-box;
  align-items: center;
  gap: 5px;
  position: relative;
  border: none;
  flex: 1 1;
  outline: none !important;
  font-size: 14px;
  font-family: var(--font2);
  background: #2196f310;
  box-shadow: 0px 0px 4px #1112;
}

.contact-us-form ._dropdown {
  max-height: 40px;
  flex: 1;
  height: 12px;
  font-size: 15px !important;
}
.contact-us-form .submit {
  height: 44px;
  font-size: 16px;
  width: 100%;
  max-width: 400px;
  margin-left: auto !important;
}

@media (min-width: 760px) {
  .contact-us-form {
    padding: 0 16px 50px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .contact_us_card .small_cont {
    width: 46%;
    flex: 1;
    min-width: 46%;
  }
}
