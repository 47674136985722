@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Wix+Madefor+Text:ital,wght@0,400..800;1,400..800&display=swap");

:root {
  --fg: #fff;
  --bg: #1b73bc09;
  --blue: #2196f3;
  /* --red: #f44336; */
  --gray: #adadad;
  --blue-dark: #1b73bc;
  --font1: Montserrat, Sans-serif;
  --font2: "Wix Madefor Text", sans-serif;
  --bc: cubic-bezier(0.215, 0.61, 0.355, 1);
  --error: #fec135;

  --yellow: rgb(253, 217, 14);
  --gold: #fec542;
  --red: #df4c4c;
  --red-dark: rgb(200, 69, 69);
  --loader-bg: linear-gradient(135deg, #1111, #1111, #1112);
  --loader-bg-size: 300%;
}

body {
  margin: 0;
  background-color: var(--bg);
  font-family: var(--font2);
}

a {
  color: var(--blue);
  text-decoration: none;
}

/* body *::-webkit-scrollbar {
  display: none;
} */

@media (max-width: 768px) {
  body::-webkit-scrollbar {
    display: none !important;
  }
}

* {
  -webkit-tap-highlight-color: transparent !important;
}

.center {
  text-align: center;
}

.logo_loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
}
.logo_loader > div {
  width: 100vw !important;
  height: 100vw !important;
  aspect-ratio: 1 / 1;
  filter: drop-shadow(1px 1px 24px #1112);
  max-width: 500px !important;
  max-height: 500px !important;
}

#root {
  position: absolute;
  top: 0;
  width: 100%;
}
body::-webkit-scrollbar {
  display: none;
}

.price .country {
  color: var(--blue);
}
.empty {
  pointer-events: none;
}
@keyframes skeleton {
  0% {
    background-position: TOP left;
  }

  90% {
    background-position: bottom right;
  }
  100% {
    background-position: TOP left;
  }
}
.empty {
  color: transparent;
  background: var(--loader-bg);
  background-size: var(--loader-bg-size);
  background-position: top left;
  border-radius: 5px;

  animation: skeleton 1.8s var(--bc) forwards infinite;
  pointer-events: none;
}
.empty svg {
  fill: transparent !important;
}
.__app {
  min-width: 1200px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--bg);
}
._success {
  height: fit-content;
  box-sizing: border-box;
  padding: 0 30px 150px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  /* padding-bottom: 60px; */
}
.mobile_modal ._success {
  padding: 0 30px 80px !important;
}
._success p {
  margin: 0;
}

._success > div {
  width: 500px !important;
  height: 500px !important;
  aspect-ratio: 1 / 1 !important;
  margin: -200px !important;
  max-width: 150vw !important;
  max-height: 150vw !important;
}
