._package {
  border-radius: 20px;
  border-radius: 20px;
  filter: drop-shadow(0px 0px 4px #1115);
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s var(--bc);
  background-color: white;
  will-change: transform;
  flex: 1;
  min-height: 580px;
  max-width: 400px;
  min-width: 300px;

  max-height: 620px;
  width: 100%;
  align-self: center;
}
._package.selected {
  box-shadow: 0px 0px 8px #1112;
}
._package .lower {
  padding: 30px;
  padding-right: 10px;
  padding-top: 50px;
  padding-bottom: 100px;
  /* display: flex; */
  /* align-items: center; */
}
._package .coloured {
  background: #555;
  padding: 30px 30px 0px;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
._package .coloured.Premium {
  background: var(--gold);
}
._package .coloured.Standard {
  background: var(--blue);
}
.plans {
  display: flex;
  justify-content: space-evenly;
  gap: 32px;
  flex-direction: row-reverse;
  padding: 20px 0;
  max-width: 1100px;
  margin: auto;
  width: 100%;
  min-width: fit-content;
}

._package .icon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
}

._package .icon > div {
  width: 180px !important;
  height: 180px !important;
  aspect-ratio: 1 / 1 !important;
  flex-direction: row;
}
._package h3 {
  display: flex;
  align-items: center;
  gap: 12px;
}

._package ._info {
  margin-left: auto;
}
._package h1 {
  color: white;
  font-size: 32px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  /* margin-bottom: 10px; */
  margin: 0;
  font-family: var(--font1);
  /* margin-bottom: 10px; */
}
/* ._package h1.Standard {
    color: var(--blue);
  }
  
  ._package h1.Premium {
    color: var(--yellow);
  } */

._package .details > div {
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 4px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  /* font-family: var(--font1); */
}
._package .coloured::after {
  z-index: -1;
  content: "";
  position: absolute;
  background: #555;
  height: 115%;
  width: 180%;
  top: 0;
  left: -40%;
  border-radius: 60%;
  transition: all 0.3s var(--bc);
}
._package .coloured.Premium::after {
  background-color: var(--gold);
}
._package .coloured.Standard::after {
  background-color: var(--blue);
}
._package .details > div span {
  height: 122x;
  width: 22px;
  /* background-color: #2196f322; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  /* box-shadow: 0px 0px 8px #ccc; */
}

._package *,
._package {
  transition: all 0.3s var(--bc);
}

._package .details span svg {
  fill: var(--blue);

  height: 22px;
  width: 22px;
}
._package .details .red {
  color: var(--red);
}
._package .details .red svg {
  fill: var(--red);
}

button.select_package {
  position: absolute;
  bottom: 16px;
  width: calc(100% - 60px);
  margin: auto;
  border: none;
  padding: 10px 20px;
  font-size: 30px;
  background-image: linear-gradient(45deg, var(--blue), var(--blue-dark));
  font-weight: 600;
  color: white;
  font-family: var(--font1);
  box-shadow: 0px 0px 8px #1113;
  border-radius: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-direction: column;
  height: 56px !important;
  box-sizing: border-box;
}

p.free_text {
  font-weight: 500;
  font-size: 12px !important;
  margin: -6px !important;
  margin-bottom: 2px !important;
}

span.fr {
  font-size: 24px;
}
button.select_package p {
  margin: 0;
  font-size: 16px;
  margin-bottom: 4px;
}

button.select_package.Basic {
  background-image: linear-gradient(45deg, #777, #555);
}

button.select_package.Premium {
  background-image: linear-gradient(45deg, var(--gold), #fbb32c);
}

._package.selected {
  transform: scale(1.04);
  /* border: 1px solid var(--blue); */
}

._package.selected::before {
  content: "selected";
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  gap: 4px;
  justify-content: CENTER;
  align-items: center;
  font-size: 16px;

  font-family: var(--font1);

  box-sizing: border-box;
  font-weight: 600;
  padding: 8px 12px;
  color: white;
  z-index: 1;
  background: var(--blue);
  border-bottom-right-radius: 4px;
  text-transform: uppercase;
}

._package.selected.Basic::before {
  background-color: #555;
}
._package.selected.Premium::before {
  background-color: var(--gold);
}

/* ._package.Premium {
    border: 3px solid var(--yellow);
  }
  ._package.Standard {
    border: 3px solid var(--blue);
  }
  ._package.Basic {
    border: 3px solid #777;
  }
  */

._add_ons {
  display: flex;
  flex-direction: column-reverse;
  box-shadow: 0px 0px 8px #ccc;
  border-radius: 4px;
}
._add_on {
  display: flex;
  width: 100%;
  height: 92px;
  background: white;
  align-items: center;
  padding: 10px 30px;
  box-sizing: border-box;
  gap: 20px;
  border-bottom: 1px solid #eee;
}

._add_on h3 {
  font-weight: 500;
  /* margin-right: auto; */
}

._add_on ._info {
  margin-right: auto;
}

._add_on span.price {
  font-size: 24px;
  font-weight: 600;
  color: var(--blue);
  /* margin: 20px; */
  font-family: var(--font2);
  width: 120px;
  text-align: right;
}

._add_on ._dropdown {
  filter: drop-shadow(0px 0px 4px #ccc);
  padding: 6px 16px;
  width: 120px;
}

._add_on ._dropdown .options {
  top: 12px;
}
._add_on ._dropdown .option {
  padding: 8px 16px;
  font-size: 16px;
}

._extra {
  min-height: 100px;
  box-shadow: 0px 0px 8px #ccc;
  margin-bottom: -20px;
}
._extra .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  border-radius: 4px;
  box-sizing: border-box;
}

._extra .info h3 {
  font-size: 22px;
  font-weight: 500;
  color: #333;
}

._extra .info .price {
  margin-left: auto;
  font-size: 30px;
  color: var(--blue);
  font-weight: 600;
}

._extra .form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  transition: all 0.1s var(--bc);
  transform: scaleY(0);
  opacity: 0;
  transform-origin: top center;
  height: 0;
}
._extra .form.active {
  opacity: 1;
  transform: scaleY(1);
  padding: 20px 0;
  height: fit-content;
}

._extra .form button {
  width: 220px;
  /* border: 1px solid var(--blue);
    background-color: white;
    color: var(--blue); */
}

.no_business_info {
  color: var(--red);
  font-size: 20px;
  font-weight: 500;
}

.business_ov {
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 18px;
}

.business_ov img {
  height: 100%;
  width: 100px;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: 0px 0px 8px #ccc;
}

.business_ov > div {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  gap: 12px;
  padding: 10px 0;
  box-sizing: border-box;
}

.business_ov h4 {
  font-size: 26px;
  font-weight: 600;
}

.business_ov p {
  margin: 0;
  color: #555;
}

._extra {
  display: flex;
  padding: 10px 30px;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  box-sizing: border-box;
  min-height: 100px;
  box-shadow: 0px 0px 8px #ccc;
  margin-bottom: -30px;
  border-radius: 4px;
}
