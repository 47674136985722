._header {
  display: flex;
  padding: 0px;
  align-items: center;
  justify-content: space-between;
  /* gap: 10px; */
  padding: 16px;
  position: absolute;
  width: calc(100%);
  height: 60px;
  box-sizing: border-box;
  z-index: 10;
  gap: 8px;
  top: 0px;
  transition: all 0.1s var(--bc);
  background-color: white;
}

._header .overlay {
  display: flex;
  position: RELATIVE;
  height: 42px;
  width: 42px;
  max-height: 42px;
  max-width: 42px;
  min-height: 42px;
  min-width: 42px;
  margin: 0;
  /* margin-left: 8px; */
  transition: all 0.1s var(--bc);
}

._header .logo {
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: start;
  align-self: flex-start;
}

._header .logo img {
  height: 32px;
  margin-right: auto;
  position: relative;
  object-fit: contain;
}
._header .option {
  border-radius: 50%;
  background: white;
  display: flex;
  box-shadow: 0px 0px 4px #1118;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;

  box-sizing: BORDER-BOX;
  background-color: var(--blue);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  /* background: #2196f322; */
  overflow: hidden;
  box-sizing: border-box;
  border: none;
}

._header .sign-in-btn {
  position: relative;
  height: 36px;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: var(--blue);
  border-radius: 4px;
  margin: -4px;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font2);
  box-shadow: 0px 0px 4px #1114;
  gap: 4px;

  /* padding-right: 6px; */
}
._header .sign-in-btn svg {
  margin: -14px;
  height: 38px;
  width: 38px;
  fill: white;
}
._header .option svg {
  height: 26px;
  width: 26px;
  fill: white;
}

._header .option img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  /* border: 2px solid var(--blue); */
}
._header .overlay .dot {
  position: absolute;
  top: 0px;
  right: 0px;
  background: var(--red);
  border-radius: 50%;
  min-width: 16px;
  height: 16px;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 10px;
  z-index: 1;
  color: white;
  padding: 3px;
  box-sizing: border-box;
}
.heading_unread {
  font-size: 14px;
  color: #555;
}

._notifications .notif img {
  width: 60px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px #1112;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  object-fit: cover;
}

._notifications .notif {
  display: flex;
  gap: 6px;
  align-items: CENTER;
  justify-content: start;
  padding: 12px;

  border-radius: 8px;
  background-color: white;
  transition: all 0.1s var(--bc);
  position: relative;
}

._notifications .notif .overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 4;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
}
._notifications .notif p {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  /* font-family: var(--font1); */
  /* letter-spacing: -0.2px; */
  line-height: 1.1;
}

._notifications {
  height: calc(100% - 80px);
  overflow-y: auto;
  padding: 6px 12px;
  gap: 6px;
  display: FLEX;
  flex-direction: COLUMN;
  filter: drop-shadow(0px 0px 4px #1113);
  margin: 12px auto;
  transition: all 0.1s var(--bc);
}
._notifications::-webkit-scrollbar {
  display: none;
}

._notifications .notif .time {
  font-size: 12px;
  font-weight: 500;
  color: #555;
  line-height: normal;
}
._notifications .notif.left {
  animation: remove_left 0.3s var(--bc) forwards;
}

._notifications .notif.right {
  animation: remove_right 0.3s var(--bc) forwards;
}
._notifications .unread {
  background-color: #2196f320;
  box-shadow: 0px 0px 1px #1116;
  /* border: 1px solid #2196f380; */
  box-sizing: border-box;
}

._notifications .no_notifs {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  gap: 12px;
  font-family: var(--font1);
  flex-direction: column;
  padding-bottom: 40px;
}
._notifications .no_notifs svg {
  height: 200px;
  width: 200px;
  animation: bell 3s var(--bc) infinite forwards;
  fill: var(--blue);
  transform-origin: top center;
}

._notifications > p {
  margin: 0;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  width: 100%;
  background: white;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
}
@keyframes bell {
  0% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}
@keyframes remove_left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes remove_right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
